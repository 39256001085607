import React, { useState, useEffect } from 'react';
import { Card, Modal, Form, Button, Select, message, Tag, ConfigProvider, Row, Col, Typography,Table,Input, Divider } from 'antd';
import { NumericFormat } from 'react-number-format'; 
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import esES from 'antd/es/locale/es_ES';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import API_BASE_URL from '../components/apiService';
import { useNavigate } from 'react-router-dom';
moment.locale('es');

const { Title } = Typography;

const Jornadas = () => {
  const [jornadas, setJornadas] = useState([]);
  const [ferrys, setFerrys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);   // Página actual
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [operationalCosts, setOperationalCosts] = useState([]);
  const [totalIngreso, setTotalIngreso] = useState(0);  
  const [userRecaudo, setUserRecaudo] = useState([]); // Nuevo estado para recaudo por usuario
  const [vehiculosTotalDetalle, setVehiculosTotalDetalle] = useState([]); // Nuevo estado para recaudo por usuario
  const [vehiculosTotalDetalleViaje, setVehiculosTotalDetalleViaje] = useState({});
  const [vehiculosTotalMatriculasViaje, setVehiculosTotalMatriculasViaje] = useState({});

  
  const [form] = Form.useForm();
  const navigate = useNavigate();
  
  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  const fetchJornadas = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/jornadas/all`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          page: currentPage,
          limit: 1,
        },
      });

      const { count, jornadas } = response.data.data;
      setJornadas(jornadas);
      setTotal(count);
      fetchGastos(response.data.data.jornadas[0].fecha_inicio);
      fetchRecaudo(response.data.data.jornadas[0].fecha_inicio);
      fetchVehiculosDetalle(response.data.data.jornadas[0].id_jornada);
    

    } catch (error) {
      message.error('Error al cargar las jornadas');
    } finally {
      setLoading(false);
    }
  };

  const fetchRecaudo = async (fecha) => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/ingresos/user/all?fecha_ini=${fecha}`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          fecha_ini: fecha,
        },
      });

      // El formato de respuesta esperado es un array de objetos con nombre y total
      setUserRecaudo(response.data.data.ingresos);  // Guardar el recaudo por usuario
    } catch (error) {
      message.error('Error al cargar los recaudos de los usuarios');
    }
  };

  
  const fetchVehiculosDetalle = async (id_jornada) => {

    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/vehiculos/detaills/all?id_jornada=${id_jornada}`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id_jornada: id_jornada,
        },
      });

      // El formato de respuesta esperado es un array de objetos con nombre y total
      setVehiculosTotalDetalle(response.data.data.details);  // Guardar el recaudo por usuario
    } catch (error) {
      message.error('Error al cargar los recaudos de los usuarios');
    }
  };

  const fetchVehiculosDetalleByViajes = async (id_viaje) => {
    // Si ya se cargaron los detalles de este viaje, no hacer la consulta nuevamente
    if (vehiculosTotalDetalleViaje[id_viaje]) {
      return; // Si ya están cargados, no hacemos la solicitud
    }
  
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }
  
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/vehiculos/detaills/viaje/all`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id_viaje: id_viaje,
        },
      });
  
      // Guardar los detalles de vehículos para el viaje específico
      setVehiculosTotalDetalleViaje((prevState) => ({
        ...prevState,
        [id_viaje]: response.data.data.details, // Se asegura que los detalles se almacenen correctamente
      }));
    } catch (error) {
      message.error('Error al cargar los detalles de los vehículos');
    }
  };

  const fetchMatriculas = async (id_viaje,id_tipo_vehiculo) => {
    // Si ya se cargaron los detalles de este viaje, no hacer la consulta nuevamente
    if (vehiculosTotalMatriculasViaje[id_viaje]) {
      return; // Si ya están cargados, no hacemos la solicitud
    }
  
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }
  
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/vehiculos/detaills/viaje/matricula`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id_viaje: id_viaje,
          id_tipo_vehiculo:id_tipo_vehiculo
        },
      });
  
      // Guardar los detalles de vehículos para el viaje específico
      setVehiculosTotalMatriculasViaje((prevState) => ({
        ...prevState,
        [`${id_viaje}_${id_tipo_vehiculo}`]: response.data.data.details, // Se asegura que los detalles se almacenen correctamente
      }));
    } catch (error) {
      message.error('Error al cargar los detalles de los vehículos');
    }
  };
  


  const fetchFerrys = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/ferrys/all`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const ferrysData = response.data.data.ferrys || [];
      setFerrys(ferrysData);
    } catch (error) {
      message.error('Error al cargar los ferrys');
    }
  };

const  navigateTrayecto = () =>{
  navigate('/opentrayecto')
}

const fetchGastos = async (fechaIni = null) => {
  setLoading(true);
  const token = localStorage.getItem('token'); // Obtener el token desde localStorage

  try {
    const fechaHoy = moment().startOf('day'); // Esto nos da la fecha actual a las 00:00:00

    // Configuramos la fecha de inicio a las 04:00:00
    // const fechaInicio = fechaHoy.clone().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
    // const fechaInicioString = fechaInicio.format('YYYY-MM-DD HH:mm:ss');
    const params = {
      page:1,
      limit: 10000, // Límite de resultados por página
      fecha_ini: fechaIni  // Añadir fecha inicio si está definida
    };

    const response = await axios.get(
      `${API_BASE_URL}/admin/gastos/all`,
      {
        params,
        headers: {
          Authorization: `${token}` // Pasamos el token en los encabezados
        }
      }
    );

    if (response.data.success) {

      const ingresos = response.data.data.gastos.filter(gasto => gasto.tipo_operacion === 'INGRESO');
      setOperationalCosts(ingresos); // Datos de los gastos

      // console.log(response.data.data.gastos)
      // let suma = 0;
      let total = 0;

      ingresos.forEach(row => {
          total += Number(row.monto);
      });
     
      // console.log(total);
      // const totalMonto = response.data.data.gastos.reduce((acumulado, viaje) => {
      //   return acumulado + parseFloat(viaje.monto);  // Convierte el monto a número antes de sumarlo
      // }, 0);
      setTotalIngreso(total);  // Actualizamos el estado con la suma
      setTotal(response.data.data.count);  // Total de registros
    } else {
      message.error('Error al obtener los datos');
    }
  } catch (error) {
    console.error('Error al hacer la solicitud', error);
    message.error('Hubo un error al cargar los datos');
  } finally {
    setLoading(false);
  }
};
  const showModal = () => {
    fetchFerrys();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const expandedRowRender = (record) => {
    // Verificar si ya se cargaron los detalles de este viaje
    if (!vehiculosTotalDetalleViaje[record.id_viaje]) {
      // Si no se han cargado, llamar a fetchVehiculosDetalleByViajes
      fetchVehiculosDetalleByViajes(record.id_viaje);
    }
  
    // Esperar los detalles antes de mostrar la tabla (puedes usar un estado para mostrar un "loading" si lo prefieres)
    const vehiculosPanel = vehiculosTotalDetalleViaje[record.id_viaje] || [];
  
    return (
      <>
        <Title level={5} style={{color:'red'}}>Cantidad Vehículos del trayecto TR-{record.id_viaje}</Title>
  
        <Table
          dataSource={vehiculosPanel}
          rowKey="tipo_vehiculo_id"
          pagination={false} // Activar el loading mientras se obtienen los datos
          style={{width:'45%'}}
          expandedRowRender={expandedRow} 
          columns={[
            {
              title: 'Tipo',
              dataIndex: 'tipo_vehiculo',
              key: 'tipo_vehiculo',
            },
            {
              title: 'Cantidad',
              dataIndex: 'cantidad',
              key: 'cantidad',
            },
          ]}
        />
      </>
    );
  };
  const expandedRow = (record) => {
    // Verificar si ya se cargaron los detalles de este viaje
    if (!vehiculosTotalMatriculasViaje[`${record.id_viaje}_${record.tipo_vehiculo_id}`]) {
      // Si no se han cargado, llamar a fetchVehiculosDetalleByViajes
      fetchMatriculas(record.id_viaje,record.tipo_vehiculo_id);
    }
  
    // Esperar los detalles antes de mostrar la tabla (puedes usar un estado para mostrar un "loading" si lo prefieres)
    const vehiculosPanel = vehiculosTotalMatriculasViaje[`${record.id_viaje}_${record.tipo_vehiculo_id}`] || [];
  
    return (
      <>
        {/* <Title level={5} style={{color:'red'}}>Cantidad Vehículos del trayecto TR-{record.id_viaje}</Title> */}
  
        <Table
          dataSource={vehiculosPanel}
          rowKey="tipo_vehiculo_id"
          pagination={false} // Activar el loading mientras se obtienen los datos
          style={{width:'45%'}}
          columns={[
            {
              title: 'Matricula',
              dataIndex: 'matricula',
              key: 'matricula',
            },
            {
              title: 'Tarifa',
              dataIndex: 'precio',
              key: 'precio',
            },
          ]}
        />
      </>
    );
  };
  
  
  const handleUpdateEstado = async (idJornada, estado) => {
    const token = getAuthToken();  // Obtener el token de localStorage
  
    if (!token) {
      message.error('No estás autenticado');
      return;
    }
  
    setLoading(true); // Activar el loading para mostrar una indicación de carga
  
    try {
      // Realizar la solicitud POST para actualizar el estado
      const response = await axios.post(
        `${API_BASE_URL}/admin/jornadas/status`,
        {
          id_jornada: idJornada,
          estado: estado
        },
        {
          headers: {
            Authorization: `${token}` // Asegúrate de pasar el token correctamente
          }
        }
      );
  
      // Verificar si la respuesta es exitosa
      if (response.data.success) {
        message.success('Estado de la jornada actualizado correctamente');
        fetchJornadas(); // Recargar las jornadas para obtener el estado actualizado
      } else {
        message.error('Error al actualizar el estado de la jornada');
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      message.error('Hubo un error al actualizar la jornada');
    } finally {
      setLoading(false); // Desactivar el loading
    }
  };
  
  const handleCreate = async (values) => {
    setLoading(true);
    const token = getAuthToken();

    try {

            // Obtener la fecha de inicio
      // Crear la fecha de fin concatenando la hora 23:59:59
   // Obtenemos la fecha de hoy sin hora
const fechaHoy = moment().startOf('day'); // Esto nos da la fecha actual a las 00:00:00

// Configuramos la fecha de inicio a las 04:00:00
const fechaInicio = fechaHoy.clone().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });

// Configuramos la fecha de fin a las 23:59:59
const fechaFin = fechaHoy.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

// Si necesitas las fechas como cadenas con el formato requerido, puedes hacer lo siguiente:
const fechaInicioString = fechaInicio.format('YYYY-MM-DD HH:mm:ss');
const fechaFinString = fechaFin.format('YYYY-MM-DD HH:mm:ss');

console.log('Fecha de Inicio:', fechaInicioString);  // Mostrará la fecha de hoy a las 04:00:00
console.log('Fecha de Fin:', fechaFinString); 
      const data = {
        ...values,
        fecha_inicio:fechaInicioString,
        fecha_fin:fechaFinString,
      };

      const response = await axios.post(`${API_BASE_URL}/admin`, data, {
        headers: {
          Authorization: `${token}`,
        },
      });
       if(response.data.data.jornada_id > 0){
        message.success('Jornada creada correctamente');
        navigate('/opentrayecto');
        setIsModalOpen(false);
        fetchJornadas();
        form.resetFields();
       }


    } catch (error) {
      message.error('Error al crear la jornada');
    } finally {
      setLoading(false);
    }
  };

  const recargarPagina = () => {
    window.location.reload();  // Esto recarga la página completa
  };

  useEffect(() => {
    fetchJornadas();
  }, [currentPage]);

  useEffect(() => {
    if (ferrys.length > 0) {
      form.setFieldsValue({
        id_ferry: ferrys[0]?.id_ferry,
      });
    }
  }, [ferrys, form]);

  useEffect(() => {
    if (jornadas.length > 0 && userRecaudo.length > 0) {
      // Asegurarse de que los datos están disponibles antes de hacer las operaciones
      // const total = userRecaudo.reduce((sum, recaudo) => sum + Number(recaudo.total_jornada), 0);
      let total = 0;

      userRecaudo.forEach(row => {
        if(row.tipo_operacion == 'INGRESO'){
          total += Number(row.monto);
        }
     
      });
      setTotalIngreso(total);
    }
  }, [jornadas, userRecaudo]);
  

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

    // Definir las columnas de la tabla
    const columns = [
      {
        title: 'Trayecto',
        dataIndex: 'trayecto',
        key: 'trayecto',
        ...getColumnSearchProps('trayecto')
      },

      {
        title: 'Ingreso',
        dataIndex: 'monto',
        key: 'monto',
        render: (amount) => (
          <NumericFormat 
            value={amount} 
            displayType="text"
            thousandSeparator={true} 
            fixedDecimalScale 
          />
        ),
      },
      {
        title: 'Vehículos',
        dataIndex: 'vehiculos',
        key: 'vehiculos',
        ...getColumnSearchProps('vehiculos')
      },
      {
        title: 'Fecha',
        dataIndex: 'fecha_creacion',
        key: 'fecha_creacion',
        ...getColumnSearchProps('fecha_creacion')
      }
    ];

  return (
    <ConfigProvider locale={esES}>
      <Card style={{ margin: '20px 0', padding: '20px' }} bordered={false}>
        <Title level={3} style={{ marginBottom: '20px', textAlign: 'center' }}>
          Jornada 
        </Title>

        <Button type="primary" onClick={showModal} style={{ marginBottom: 20, display: 'block', marginLeft: 'auto', marginRight: 'auto',width:365,height:60 }}>
          Abrir Nueva Jornada
        </Button>
        
        <Button type="primary" onClick={navigateTrayecto} style={{ marginBottom: 20, display: 'block', marginLeft: 'auto', marginRight: 'auto',width:365,height:60 }}>
          VER Trayecto actual
        </Button>
        <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={() => recargarPagina()}  // Deshabilitar si el estado es 0
                  style={{ marginBottom: '10px',width:365,height:60 }}
                >
                 Actualizar
                </Button>

        <Row gutter={[16, 16]}>
          {jornadas.map((jornada) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={4} key={jornada.id_jornada}>
              <Card
                bordered
                hoverable
                title={`${jornada.ferry_nombre}`}

              >
                <p> {"\u00A0"} <strong className='titles'>Codigo de jornada:</strong>    {"\u00A0"}  J{jornada.id_jornada}</p>
                <p>{"\u00A0"} <strong className='titles'>Fecha Inicio:</strong>    {"\u00A0"} {moment(jornada.fecha_inicio).format('YYYY-MM-DD HH:mm:ss')}</p>
                <p>{"\u00A0"} <strong className='titles'>Fecha Fin:</strong>     {"\u00A0"} {moment(jornada.fecha_fin).format('YYYY-MM-DD HH:mm:ss')}</p>
                <p>{"\u00A0"} <strong className='titles'>Abierta por:</strong>  {"\u00A0"}  {jornada.usuario_nombre}</p>
                <p>{"\u00A0"} <strong className='titles'>Recaudo total:</strong>  {"\u00A0"} <NumericFormat value={jornada.total_recaudo} displayType="text" thousandSeparator={true} prefix="$" decimalScale={0} fixedDecimalScale /> </p>
                <p>{"\u00A0"} <strong className='titles'>Estado:</strong>   {"\u00A0"} <Tag color={jornada.estado === 1 ? 'green' : 'red'}>{jornada.estado === 1 ? 'Jornada Abierta' : 'Jornada Cerrada'}</Tag></p>
                {userRecaudo.length > 0 ? (
                  <ul>
                    {userRecaudo.map((recaudo, index) => (
                      <li key={index}>
                        <strong className='titles'>Operador:</strong> {recaudo.nombre} <br />
                        <strong className='titles'>recaudo: </strong>
                         <NumericFormat  value={recaudo.total_jornada} displayType="text" thousandSeparator={true} prefix="$" decimalScale={2} fixedDecimalScale />
                         <br /><strong className='titles'>Inicio:</strong> {recaudo.fecha_creacion_dos} 
                         <br /><strong className='titles'>Fin:</strong> {recaudo.fecha_creacion} 
                        <Divider></Divider>
                      </li>
                    
                    ))}
                  </ul>
                ) : (
                  <p>No hay recaudos disponibles.</p>
                )}
              {"\u00A0"} 
            
              <Button
                type="primary"
                onClick={() => handleUpdateEstado(jornada.id_jornada, jornada.estado === 1 ? 0 : 1)} // Cambia el estado
                style={{ marginTop: 10, background:jornada.estado === 1 ? 'red' : 'green'}}
              >
                {jornada.estado === 1 ? 'Cerrar Jornada' : 'Abrir Jornada'}
              </Button>
              <br></br>
              <br></br>
              </Card>

              <Title level={3} style={{ marginBottom: '20px', textAlign: 'center' ,color:'red'}}>
         TRAYECTOS DEL DIA
        </Title>

        <Table
          columns={columns}
          dataSource={operationalCosts}
          rowKey="id_viaje"
          loading={loading} // Mostrar carga mientras se obtienen los datos
          scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
          responsive={true} 
          expandedRowRender={expandedRowRender} // Habilita la responsividad de la tabla
          footer={() => (
            <div style={{ textAlign: 'right', fontSize: 20, color: "#00bf3f", fontWeight: 'bold' }}>
              {/* <span style={{ color: "#040b06" }}>Total Ingreso:</span> <NumericFormat value={totalIngreso} displayType="text" thousandSeparator={true} prefix="$" decimalScale={0} fixedDecimalScale /> */}
            </div>
          )}
          pagination={false} // Desactivar la paginación
        />
              <Title level={3} style={{ marginBottom: '20px', textAlign: 'center' ,color:'red'}}>
        VEHÍCULOS DE LA JORNADA
        </Title>
      <Table
          columns={
            [
              {
                title: 'Vehículo',
                dataIndex: 'tipo_vehiculo',
                key: 'tipo_vehiculo',
                ...getColumnSearchProps('tipo_vehiculo')
              },
              {
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                ...getColumnSearchProps('cantidad')
              }
            ]
          }
          dataSource={vehiculosTotalDetalle}
          rowKey="tipo_vehiculo"
          loading={loading} // Mostrar carga mientras se obtienen los datos
          scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
          responsive={true}  // Habilita la responsividad de la tabla
          pagination={false} // Desactivar la paginación
        />
      <Table
          columns={
            [
              {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',

              }
            ]
          }
          dataSource={vehiculosTotalDetalle.slice(0, 1)}
          rowKey="id_tipo_vehiculo"
          loading={loading} // Mostrar carga mientras se obtienen los datos
          scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
          responsive={true}  // Habilita la responsividad de la tabla
          pagination={false} // Desactivar la paginación
        />


            </Col>
          ))}
        </Row>

        <Modal
          title="Abrir Jornada"
          visible={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose
          width={600}
        >
          <Form form={form} onFinish={handleCreate} layout="vertical">
            <Form.Item
              name="id_ferry"
              label="Ferry"
              rules={[{ required: true, message: 'Selecciona un ferry' }]}
            >
              <Select placeholder="Selecciona un ferry">
                {ferrys.map((ferry) => (
                  <Select.Option key={ferry.id_ferry} value={ferry.id_ferry}>
                    {ferry.ferry}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Button type="primary" htmlType="submit" block loading={loading}>
              ENVIAR
            </Button>
          </Form>
        </Modal>
      </Card>
    </ConfigProvider>
  );
};

export default Jornadas;
