import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../components/apiService';

const Login = ({ setIsAuthenticated }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const credentials = {
      usuario: values.username,
      password: values.password,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/login/`, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa('xandbox:pA2yey4CJ2SqDLtkaTxKq3PWn4YDdEMX'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      console.log("Respuesta del servidor:", data);

      if (response.ok && data.success && data.data.token) {
        // Guardar el token en localStorage
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('user', JSON.stringify(data.data));

        console.log("Token guardado:", localStorage.getItem('token'));

        // Actualizar el estado de autenticación
        setIsAuthenticated(true);

        navigate('/');
        window.location.reload();
      } else {
        message.error('Credenciales inválidas o error en el login');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      message.error('Hubo un error al procesar el login');
    }

    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <div style={{ maxWidth: 400, width: '100%' }}>
        <Card
          style={{ padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
          title={<div style={{ textAlign: 'center' }}><img src={require('../assets/logo-transdier.jpg')} alt="Logo Transdier" style={{ width: '150px', height: 'auto', marginBottom: '20px' }} /></div>}
        >
          <Form name="login" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
            <Form.Item 
              name="username" 
              rules={[{ required: true, message: 'Por favor ingrese su correo electrónico' }, { type: 'email', message: 'El correo electrónico no es válido' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Correo electrónico" size="large" />
            </Form.Item>
            <Form.Item 
              name="password" 
              rules={[{ required: true, message: 'Por favor ingrese su contraseña' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" size="large" />
            </Form.Item>
            <Form.Item>
              <Checkbox style={{ fontSize: '16px' }}>Recuérdame</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                block 
                loading={loading} 
                size="large" 
                style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
              >
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
