import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Layout, Menu, ConfigProvider, Button, Avatar, Typography, Image } from 'antd';
import { UserOutlined, LogoutOutlined, HomeOutlined, SolutionOutlined, FileTextOutlined, CalendarOutlined, SettingOutlined, MoneyCollectFilled, PlayCircleOutlined, MenuOutlined, DashOutlined, MoneyCollectOutlined, DashboardFilled } from '@ant-design/icons';
import Login from './screen/Login';
import Dashboard from './components/Dashboard';
import AdminUsuarios from './screen/AdminUsuarios';
import Reportes from './screen/Reportes';
import Jornadas from './screen/Jornadas';
import Configuraciones from './screen/Configuraciones';
import Tutoriales from './screen/Tutoriales';
import InvoiceForm from './screen/InvoiceForm';
import theme from './theme';
import InvoiceDetail from './screen/InvoiceDetail';
import GastosOperacion from './screen/GastosOperacion';
import OpenTrayecto from './screen/openTrayecto';
import PlacasExoneradas from './screen/Exoneradas';
import ReporteMensual from './screen/ReporteMensual';

const { Header, Content } = Layout;
const { Title } = Typography;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado de autenticación
  const [isLoading, setIsLoading] = useState(true); // Estado de carga
  const [collapsed, setCollapsed] = useState(false); // Estado para mostrar u ocultar el menú
  const [userType, setUserType] = useState(null); // Estado para almacenar el tipo de usuario
  const navigate = useNavigate();

  // Verificar el token y user_type_id en localStorage al cargar la aplicación
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user')); // Obtener el objeto JSON 'user'
    
    if (token && user) {
      setIsAuthenticated(true);
      setUserType(user.user_type_id); // Asignar el user_type_id
    } else {
      setIsAuthenticated(false);
    }

    setIsLoading(false);

    // Escuchar cambios en localStorage
    const storageListener = () => {
      const updatedToken = localStorage.getItem('token');
      const updatedUser = JSON.parse(localStorage.getItem('user'));
      
      if (updatedToken && updatedUser) {
        setIsAuthenticated(true);
        setUserType(updatedUser.user_type_id);
      } else {
        setIsAuthenticated(false);
        setUserType(null);
      }
    };

    window.addEventListener('storage', storageListener);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []); // Solo ejecuta este useEffect al montar el componente

  // Manejador para cerrar sesión
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUserType(null);
    navigate('/login');
  };

  const navegate = (url) => {
    navigate(url);
  };

  // Ruta protegida para redirigir si no está autenticado o si el usuario no tiene permisos
  const ProtectedRoute = ({ children, allowedUserTypes }) => {
    if (isLoading) {
      return null; // Mientras estamos cargando, no hacer nada
    }

    if (!isAuthenticated || (allowedUserTypes && !allowedUserTypes.includes(userType))) {
      return <Navigate to="/login" />; // Si no está autenticado o no tiene el user_type permitido, redirige al login
    }

    return children; // Si está autenticado y tiene el tipo de usuario permitido, muestra el contenido
  };

  // Verificar si el usuario está autenticado para no dejarlo acceder a /login
  const LoginRedirect = () => {
    if (isAuthenticated) {
      return <Navigate to="/" />; // Si está autenticado, redirige al dashboard
    }
    return <Login setIsAuthenticated={setIsAuthenticated} />;
  };

  return (
    <ConfigProvider theme={theme}>
      <Routes>
        {/* Ruta de login con redirección si ya está autenticado */}
        <Route path="/login" element={<LoginRedirect />} />

        {/* Ruta principal (contenido protegido) */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout style={{ minHeight: '100vh' }}>
                {/* Header (Menú superior) */}
                <Header
                  style={{
                    padding: '0 24px',
                    background: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div className="logo" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    {/* Avatar y nombre del usuario más pequeños */}
                    <Avatar size={40} icon={<UserOutlined />} style={{ marginRight: '10px' }} />
                    <Title level={4} style={{ color: '#000', marginTop: '0' }}>Admin</Title>
                  </div>
                  {/* Icono de hamburguesa */}
                  <Button
                    type="primary"
                    icon={<MenuOutlined />}
                    onClick={() => setCollapsed(!collapsed)} // Toggle para mostrar u ocultar el menú
                    style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white' }}
                    className="menu-toggle"
                  />
                  <Button
                    type="primary"
                    icon={<LogoutOutlined />}
                    onClick={handleLogout}
                    danger
                    style={{
                      backgroundColor: '#ff4d4f',
                      borderColor: '#ff4d4f',
                      color: 'white',
                    }}
                  >
                    Cerrar sesión
                  </Button>
                </Header>

                {/* Menú Horizontal en pantallas grandes y Menú Vertical en pantallas pequeñas */}
                <Menu
                  mode={collapsed ? 'inline' : 'horizontal'} // Cambiar a 'inline' en pantallas pequeñas
                  theme="light"
                  defaultSelectedKeys={['1']}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    transition: 'all 0.3s',
                    ...(collapsed ? { position: 'absolute', top: '64px', left: 0, right: 0, zIndex: 1000, backgroundColor: '#fff' } : {}),
                  }}
                >
                  {userType === 1 && (
                  <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => navigate('/')}>Inicio</Menu.Item>)}
                  {userType === 1 && (
                  <Menu.Item key="2" icon={<SolutionOutlined />} onClick={() => navigate('/usuarios')}>Usuarios</Menu.Item> )}
                  {userType === 1 && (
                  <Menu.Item key="3" icon={<CalendarOutlined />} onClick={() => navigate('/jornadas')}>Jornadas</Menu.Item>)}
                  {userType === 1 && (
                  <Menu.Item key="4" icon={<FileTextOutlined />} onClick={() => navigate('/reportes')}>HISTORIAL DE VIAJES</Menu.Item>)}
                  {userType === 1 && (
                  <Menu.Item key="5" icon={<SettingOutlined />} onClick={() => navigate('/configuracion')}>Configuración</Menu.Item>)}

                  {userType === 1 && (
                    <Menu.Item key="6" icon={<MoneyCollectOutlined />} onClick={() => navigate('/gastos')}>Ingresos y Egresos</Menu.Item>)}
                    <Menu.Item key="7" icon={<MoneyCollectFilled />} onClick={() => navigate('/opentrayecto')}>Trayectos</Menu.Item>
                     <Menu.Item key="8" icon={<PlayCircleOutlined />} onClick={() => navigate('/tutoriales')}>Tutoriales</Menu.Item>
                     <Menu.Item key="9" icon={<PlayCircleOutlined />} onClick={() => navigate('/mensual')}>Reporte mensual</Menu.Item>
                   
                </Menu>

                {/* Layout Principal */}
                <Content
                  style={{
                    padding: '24px',
                    margin: 0,
                    minHeight: 280,
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            flexDirection: 'column',
                          }}
                        >
                          {/* Logo de Transdier */}
                          <Image
                            src="/assets/logo-transdier.jpg"
                            alt="Logo de Transdier"
                            preview={false}
                            width={150}
                          />
                          <br></br>
                          <br></br>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/jornadas')}
                            icon={<CalendarOutlined />}
                            size="small"
                            style={{ width: 300, height:60 }}
                          >
                            ADMINISTRACION DE JORNADAS
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/gastos')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            INGRESOS Y EGRESOS
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/reportes')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            HISTORIAL DE JORNADAS
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/usuarios')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            USUARIOS
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/exoneradas')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            PLACAS EXONERADAS Y ESPECIALES
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/mensual')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            REPORTE MENSUAL
                          </Button>
                          <br></br>
                          <Button
                            type="primary"
                            onClick={() => navegate('/configuracion')}
                            icon={<DashboardFilled />}
                            size="small"
                            style={{ width: 300 ,height:60}}
                          >
                            CONFIGURACION
                          </Button>
                        </div>
                      }
                    />
                    {/* Aquí definimos las rutas protegidas por tipo de usuario */}
                    <Route path="/dashboard" element={<ProtectedRoute allowedUserTypes={[1, 2]}><Dashboard /></ProtectedRoute>} />
                    <Route path="/usuarios" element={<ProtectedRoute allowedUserTypes={[1]}><AdminUsuarios /></ProtectedRoute>} />
                    <Route path="/reportes" element={<ProtectedRoute allowedUserTypes={[1]}><Reportes /></ProtectedRoute>} />
                    <Route path="/jornadas" element={<ProtectedRoute allowedUserTypes={[1]}><Jornadas /></ProtectedRoute>} />
                    <Route path="/configuracion" element={<ProtectedRoute allowedUserTypes={[1]}><Configuraciones /></ProtectedRoute>} />
                    <Route path="/exoneradas" element={<ProtectedRoute allowedUserTypes={[1]}><PlacasExoneradas /></ProtectedRoute>} />
                    <Route path="/tutoriales" element={<Tutoriales />} />
                    <Route path="/opentrayecto" element={<OpenTrayecto />} />
                    <Route path="/facturacion/:id/:idtrayecto" element={<ProtectedRoute allowedUserTypes={[1,2]}><InvoiceForm /></ProtectedRoute>} />
                    <Route path="/facturadetails/:id/:idviaje/:idtrayecto" element={<InvoiceDetail />} />
                    <Route path="/gastos" element={<GastosOperacion />} />
                    <Route path="/mensual" element={<ReporteMensual />} />
                  </Routes>
                </Content>
              </Layout>
            </ProtectedRoute>
          }
        >
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
};

export default App;
