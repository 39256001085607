import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Divider, Row, Col, DatePicker, Select, message, Modal, Table, Tag } from 'antd';
import { NumericFormat } from 'react-number-format'; 
import axios from 'axios';
import API_BASE_URL from '../components/apiService';
import { SearchOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf'; 
import 'jspdf-autotable'; 
import moment from 'moment';  

const { Title } = Typography;
const { Option } = Select;

function ReporteMensual() {
  const [totalingresos, setTotalIngresos] = useState('');
  const [totalegresos, setTotalEgresos] = useState('');
  const [balanceTotal, setBalanceTotal] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [fechaIni, setFechaIni] = useState(null);  
  const [mes, setMes] = useState(null);  
  const [diaInicio, setDiaInicio] = useState('');
  const [diaFin, setDiaFin] = useState('');
    
  const [anio, setAnio] = useState(null);  // Estado para el año



  const [isModalVisible, setIsModalVisible] = useState(false);
  const [operationalCosts, setOperationalCosts] = useState([]);
  const [total, setTotal] = useState(0); 
  const [page, setPage] = useState(1);   
  const [loading, setLoading] = useState(false); 

  // Función para actualizar la fecha con año, mes y día
  const handleFechaChange = () => {
    if (anio && mes && diaInicio) {
      const fechaFormateada = moment(`${anio}-${mes}-${diaInicio}`, 'YYYY-MM-DD');
      setFechaIni(fechaFormateada);
    }
  };

  const createStartDate = () => {
    if (mes && diaInicio && fechaIni) {
      return moment(`${fechaIni.year()}-${mes}-${diaInicio}`, 'YYYY-MM-DD').format('YYYY-MM-DD');  // Formato deseado: 'YYYY-MM-DD'
    }
    return null;
  };
  
  const createEndDate = () => {
    if (mes && diaFin && fechaIni) {
      return moment(`${fechaIni.year()}-${mes}-${diaFin}`, 'YYYY-MM-DD').format('YYYY-MM-DD');  // Usamos el mismo concepto para la fecha final
    }
    return null;
  };
  
  const fetchGastos = async (page = 1) => {
    setLoading(true);
    const token = localStorage.getItem('token');
  
    try {
      const params = {
        page:1,
        limit: 1000,
         fecha_ini: createStartDate() ,
         fecha_fin: createEndDate(),
      };
  
      const response = await axios.get(
        `${API_BASE_URL}/admin/reportemensual/all`,
        {
          params,
          headers: {
            Authorization: `${token}`,
          },
        }
      );
  
      if (response.data.success) {
        setOperationalCosts(response.data.data.gastos);
        setTotal(response.data.data.count);
        // console.log( response.data.data.gastos[0].to)
        const { total_ingresos, total_egresos, balance } = response.data.data.gastos[0]; 
        setTotalIngresos(total_ingresos); // Este es el total de ingresos
        setTotalEgresos(total_egresos); // Este es el total de egresos
        setBalanceTotal(balance); // Este es el total de egresos
        
      } else {
        message.error('Error al obtener los datos');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud', error);
      message.error('Hubo un error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };
  

//   useEffect(() => {
//     if (mes || diaInicio || diaFin) {
   
//     }
//   }, [mes, diaInicio, diaFin, page]);

  const handlePageChange = (page) => {
    setPage(page);  
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: 'Descripcion',
      dataIndex: 'trayecto',
      key: 'trayecto',
      ...getColumnSearchProps('trayecto')
    },
    {
      title: 'Vehículos',
      dataIndex: 'vehiculos',
      key: 'vehiculos',
      ...getColumnSearchProps('vehiculos')
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      render: (amount) => (
        <NumericFormat
          value={amount}
          displayType="text"
          thousandSeparator={true}
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
        />
      ),
    },
    {
      title: 'Movimiento',
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion',
      render: (_, record) => (
        <Tag color={record.tipo_operacion === 'INGRESO' ? 'green' : 'red'}>
          {record.tipo_operacion === 'INGRESO' ? 'INGRESO' : 'EGRESO'}
        </Tag>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      ...getColumnSearchProps('fecha_creacion')
    }
  ];

  const paginationConfig = {
    current: page,
    total: total,
    pageSize: 1000,
    onChange: handlePageChange,
  };

  const handleGetTotalAmount = async () => {
    if (fechaIni) {
      const fechaIniFormatted = fechaIni.format('YYYY-MM-DD');
      
      const token = localStorage.getItem('token'); 

      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/ingresos/all?fecha_ini=${fechaIniFormatted}`,
          {
            headers: {
              Authorization: `${token}`
            }
          }
        );
        if (response.data.success) {
          const totalPrecio = response.data.data.jornadas[0].total;
        //   setTotalAmount(totalPrecio); 
          message.success('Monto total obtenido exitosamente');
        } else {
          message.error('Error al obtener el monto total');
        }
      } catch (error) {
        console.error('Error al obtener el monto total', error);
        message.error('Error al obtener el monto total');
      }
    } else {
      message.error('Por favor, seleccione una fecha de inicio.');
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
  
    const payload = {
    //   monto_total_periodo: totalAmount,  
      fecha_inicial: fechaIni.format('YYYY-MM-DD'),  
      descripcion_gasto_operacional: description,  
      total_gasto_operacional: amount,  
    };
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/admin/gastooperacional`, 
        payload,
        {
          headers: {
            Authorization: `${token}`,
          }
        }
      );
      
      if (response.data.success) {
        message.success('Gasto operacional creado con éxito');
        
        // setTotalAmount('');
        setDescription('');
        setAmount('');
        setFechaIni(null);
  
        if (fechaIni) {
          fetchGastos(1, fechaIni.format('YYYY-MM-DD'));
        }  
  
        setIsModalVisible(false);
      } else {
        message.error('Hubo un error al crear el gasto operacional');
      }
    } catch (error) {
      console.error('Error al enviar los datos al API', error);
      message.error('Hubo un error al enviar los datos');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      return '$0.00'; 
    }
    return `$${numberValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text('Ingresos Diarios vs Gastos', 14, 20);
  
    doc.autoTable({
      head: [
        ['Descripcion del movimiento', 'Tipo de movimiento', 'Monto', 'Cantidad de vehículos', 'Fecha']
      ],
      body: operationalCosts.map(record => [
        record.trayecto,
        record.tipo_operacion,
        formatCurrency(record.monto),
        record.vehiculos,
        record.fecha_creacion,
      ]),
      startY: 30, 
      theme: 'striped',
    });

    // const totalIngresos = calculateTotalIngresos();
    // const totalEgresos = calculateTotalEgresos();
    // const balanceTotal = totalingresos + totalegresos;

    const startY = doc.lastAutoTable.finalY + 10;  

    doc.setFontSize(12);
    doc.text(`Total Ingresos: ${formatCurrency(totalingresos)}`, 14, startY);
    doc.text(`Total Egresos: ${formatCurrency(totalegresos)}`, 14, startY + 10);
    doc.text(`Balance Total: ${formatCurrency(balanceTotal)}`, 14, startY + 20);

    doc.save('Balance Diario.pdf');
  };

//   const calculateTotalIngresos = () => {
//     return operationalCosts
//       .filter((record) => record.tipo_operacion === 'INGRESO')
//       .reduce((acc, record) => acc + parseFloat(record.monto), 0);
//   };

//   const calculateTotalEgresos = () => {
//     return operationalCosts
//       .filter((record) => record.tipo_operacion === 'EGRESO')
//       .reduce((acc, record) => acc + parseFloat(record.monto), 0);
//   };

  return (
    <div style={{ padding: '10px' }}>
      <Title level={5}>Ingresos y egresos generales</Title>

     

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={8}>
          <Select
            style={{ width: '100%' }}
            value={anio}
            onChange={(value) => setAnio(value)}
            placeholder="Seleccione el año"
            onBlur={handleFechaChange}
          >
            {Array.from({ length: 5 }, (_, i) => (
              <Option key={2024 + i} value={2024 + i}>
                {2024 + i}
              </Option>
            ))}
          </Select>
          {"\u00A0"}
        </Col>

        <Col xs={24} sm={8}>
          <Select
            style={{ width: '100%' }}
            value={mes}
            onChange={(value) => setMes(value)}
            placeholder="Seleccione mes"
            onBlur={handleFechaChange}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <Option key={i + 1} value={i + 1}>
                {moment().month(i).format('MMMM')}
              </Option>
            ))}
          </Select>
          {"\u00A0"}
        </Col>

        <Col xs={12} sm={8}>
          <Input
            type="number"
            value={diaInicio}
            onChange={(e) => setDiaInicio(e.target.value)}
            onBlur={handleFechaChange}
            placeholder="Día de inicio"
          />
        </Col>
       
        <Col xs={12} sm={8}>
          <Input
            type="number"
            value={diaFin}
            onChange={(e) => setDiaFin(e.target.value)}
            onBlur={handleFechaChange}
            placeholder="Día Fin"
          />
        </Col>
      </Row>
      <Button type="primary" onClick={ fetchGastos} style={{ marginBottom: 20 }} block>
        Buscar
      </Button>
      <Table
        columns={columns}
        dataSource={operationalCosts}
        rowKey="idgasto_operacional"
        pagination={false}
        loading={loading}
        scroll={{ x: 'max-content' }}
        responsive={true}
      />
        <Table
          columns={[
            {
              title: 'Ingresos',
              dataIndex: 'totalIngresos',
              key: 'totalIngresos',
              style:{
                with:'5%'
              },
              render: () => formatCurrency(totalingresos),  // Formatear como moneda
            },
            {
              title: 'Egresos',
              dataIndex: 'totalEgresos',
              key: 'totalEgresos',
              style:{
                with:'5%'
              },
              render: () => formatCurrency(totalegresos),  // Formatear como moneda
            },
            {
              title: 'Total',
              dataIndex: 'balanceTotal',
              key: 'balanceTotal',
              style:{
                with:'5%'
              },
              render: () => formatCurrency(balanceTotal),  // Formatear como moneda
            },
          ]}
          dataSource={operationalCosts.slice(0, 1)}
          responsive={true} 
          scroll={{ x: 'max-content' }}
          pagination={false}  // Sin paginación ya que solo hay una fila
          showHeader={true}  // Muestra los encabezados
          footer={null}  // No necesitas footer en esta tabla
          style={{ marginTop: 20 }}  // Estilo opcional para separar las tablas
        
        />
      <Divider />

      <Button type="default" onClick={exportToPDF} style={{ marginBottom: 20 }} block>
        Exportar a PDF
      </Button>

      <Modal
        title="Agregar Gasto Operacional"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="90%"
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Fecha del egreso">
                <DatePicker
                  style={{ width: '100%' }}
                  value={fechaIni}
                  onChange={(date) => setFechaIni(date)}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Descripción del Gasto Operacional">
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Ejemplo: Pago de nómina"
            />
          </Form.Item>

          <Form.Item label="Monto del Gasto Operacional">
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              customInput={Input}
              placeholder="Monto del gasto operacional"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block>
              Enviar Gasto Operacional
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ReporteMensual;
