import React, { useState, useEffect } from 'react';
import { Form, Select, Button, message, Card, Row, Col, Typography, Tag, Modal, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusCircleOutlined, EyeOutlined, PoweroffOutlined, ReloadOutlined, FileImageOutlined } from '@ant-design/icons';  // Importar los iconos de Ant Design
import API_BASE_URL from '../components/apiService';  // Cambia la ruta si es necesario
import { fontSize, height } from '@mui/system';
import axios from 'axios';
const { Title } = Typography;
const { Option } = Select;

function OpenTrayecto() {
  const [loading, setLoading] = useState(false);
  const [trayectos, setTrayectos] = useState([]);  // Trayectos para el formulario
  const [selectedTrayecto, setSelectedTrayecto] = useState('');  // Trayecto seleccionado
  const [viajes, setViajes] = useState([]);  // Viajes para las cards
  const [page, setPage] = useState(1);  // Páginas de resultados de viajes
  const [total, setTotal] = useState(0);  // Total de viajes
  const [searchText, setSearchText] = useState('');  // Para filtrar por trayecto
  const [modalVisible, setModalVisible] = useState(false); // Estado del modal
  const [vehiculos, setVehiculos] = useState([]); // Vehículos para la tabla del modal
  const navigate = useNavigate();
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const user = JSON.parse(localStorage.getItem("user")); 
  const isAdmin = user && user.user_type_id === 1;

  // Función para obtener los trayectos desde la API
  const fetchTrayectos = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/trayectos/all`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.success) {
        setTrayectos(data.data.trayectos);
      } else {
        message.error('No se pudieron cargar los trayectos');
      }
    } catch (error) {
      console.error('Error al obtener trayectos:', error);
      message.error('Error al obtener los trayectos');
    }
  };

  // Función para obtener los viajes desde la API
  const fetchViajes = async (page, limit, search = '') => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/viajes/abiertos?page=${1}&limit=${1}&search=${search}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        setViajes(data.data.viajes);
        setTotal(data.data.total);
        // console.log(data.data.viajes[0])
        fetchVehiculos(data.data.viajes[0].id_viaje, data.data.viajes[0].id_trayecto)
      } else {
        message.error('No se pudieron cargar los viajes');
      }
    } catch (error) {
      console.error('Error al obtener viajes:', error);
      // message.error('Error al obtener los viajes');
    }
  };

  const showVehicleInfo = async (vehiculo_id) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    setModalVisible(true);
    setVehicleInfo(null);
    try {
      // Realizar la consulta a la API para obtener la información del vehículo
      const response = await axios.get(
        `${API_BASE_URL}/admin/vehiculo/${vehiculo_id}`,
        {
          headers: {
            'Authorization': `${token}`  // Asegúrate de que el token esté correctamente formateado
          }
        }
      );

      // Almacenar la información del vehículo en el estado
      setVehicleInfo({
        ...response.data.data.vehiculo,
        imagen: response.data.imagen || 'https://via.placeholder.com/150' // Imagen de prueba si no se obtiene una
      });
    } catch (error) {
      console.error('Error al obtener la información del vehículo:', error);
      setVehicleInfo(null); // En caso de error, vaciar la información del vehículo
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivate = async (record) => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }
  
    try {
      // Llamamos a la API para eliminar el viaje (o los vehículos relacionados)
      const response = await fetch(`${API_BASE_URL}/operator/viajes/eliminar?id_viaje=${record.id_viaje}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
  
      if (data.success && data.data.viajes[0].result === 1) {
        // Si el resultado es 1, mostramos un mensaje de éxito
        message.success('Trayecto cerrado con éxito y vehículo(s) eliminados');
        
        // Recargamos la tabla de viajes
        fetchViajes(page, 10, searchText);
      } else {
        message.error('No se pudo cerrar el trayecto');
      }
    } catch (error) {
      console.error('Error al cerrar trayecto:', error);
      message.error('Error al cerrar trayecto');
    }
  };

  const handleDeleteTrayecto = async (record) => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }
  
    try {
      
      // Preparamos el cuerpo de la solicitud
      const body = {
        id_viaje: record.id_viaje,
        is_delete: record.is_delete == 1 ? 0 : 1,  // O puedes cambiar el valor a 1 dependiendo del caso
      };
  
      // Llamamos a la API para eliminar el trayecto
      const response = await fetch(`${API_BASE_URL}/admin/trayecto/delete`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,  // Asegúrate de usar Bearer si es necesario
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),  // Enviamos el cuerpo como JSON
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Si la respuesta es exitosa, mostramos un mensaje de éxito
        message.success('Trayecto eliminado con éxito');
  
        // Recargamos la tabla de trayectos
        fetchViajes(page, 10, searchText);
      } else {
        message.error('No se pudo eliminar el trayecto');
      }
    } catch (error) {
      console.error('Error al eliminar trayecto:', error);
      message.error('Error al eliminar trayecto');
    }
  };
    const handleDelete = async (id_vehiculo, id_viaje) => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/viajes/vehiculos/eliminar`, {
        method: 'DELETE',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id_vehiculo,
          id_viaje
        })
      });

      const data = await response.json();
      if (data.success) {
        message.success('Vehículo eliminado con éxito');
        // Actualizamos la lista de vehículos
        setVehiculos(vehiculos.filter(vehiculo => vehiculo.id_vehiculo !== id_vehiculo));
      } else {
        message.error('No se pudo eliminar el vehículo');
      }
    } catch (error) {
      console.error('Error al eliminar vehículo:', error);
      message.error('Error al eliminar vehículo');
    }
  };

  // Función para obtener los vehículos del viaje
  const fetchVehiculos = async (id_viaje, trayecto_id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/viajes/vehiculos?id_viaje=${id_viaje}&trayectos_id=${trayecto_id}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        setVehiculos(data.data.viajes);  // Establecemos los vehículos obtenidos  // Abrimos el modal
      } else {
        message.error('No se pudieron cargar los vehículos');
      }
    } catch (error) {
      console.error('Error al obtener vehículos:', error);
      message.error('Error al obtener los vehículos');
    }
  };

  useEffect(() => {
    fetchTrayectos();
    fetchViajes(page, 1, searchText);
  }, [page, searchText]);

  const formatNumber = (num) => {
    if (isNaN(num)) {
      return num;
    }
    const numberWithoutDecimals = Math.floor(num);
    return numberWithoutDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };
  const navigateJornada = () => {
  navigate('/jornadas')
  };
  const recargarPagina = () => {
    window.location.reload();  // Esto recarga la página completa
  };
  const handleOpenTrayecto = async () => {
    if (!selectedTrayecto) {
      message.error('Por favor seleccione un trayecto');
      return;
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const fecha_crea = `${year}-${month}-${day} ${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}:59`;

    const token = localStorage.getItem("token");
    if (!token) {
      message.error('Token no encontrado.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/open-trayecto`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          trayecto_id: selectedTrayecto,
          fecha_crea: fecha_crea
        })
      });

      const data = await response.json();
      if (data.success) {
        message.success('Trayecto abierto con éxito');
        navigate('/facturacion/' + data.data.response + '/' + selectedTrayecto);
      } else {
        message.error(data.error.message);
      }
    } catch (error) {
      console.error('Error al abrir el trayecto:', error);
      message.error('Error al abrir el trayecto');
    }
  };

  return (
    <div className="container" style={{ padding: '30px' }}>
      <Title level={2}>Trayecto</Title>

      <Form layout="vertical" onFinish={handleOpenTrayecto}>
        <Form.Item
          label="Seleccionar Trayecto"
          name="trayecto"
          rules={[{ required: true, message: 'Por favor seleccione un trayecto' }]}>
          <Select
            value={selectedTrayecto}
            onChange={setSelectedTrayecto}
            placeholder="Seleccione un trayecto"
            style={{height:60}}
          >
            {trayectos.map((trayecto) => (
              <Option key={trayecto.id_trayecto} value={trayecto.id_trayecto} style={{fontSize:'20px'}}>
                {trayecto.trayecto}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="center">
          <Col span={24}>
            <Button type="primary" htmlType="submit" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto',width:365 ,height:60 }}>
              Abrir Nuevo Trayecto
            </Button>
            <br></br>
            <Button type="primary" onClick={navigateJornada} style={{ marginBottom: 20, display: 'block', marginLeft: 'auto', marginRight: 'auto',width:365 ,height:60 }}>
              Regresar a Jornadas
           </Button>
          </Col>
        </Row>
      </Form>

      <Row gutter={[16, 16]}>
        {viajes.map((viaje) => (
          <Col span={24} key={viaje.id_viaje}>
            <Card
              title={`TR-${viaje.id_viaje}`}
              bordered={false}
              style={{ width: '100%' }}  // Hacer que el card ocupe todo el ancho disponible
            >
              <p><strong className='titles'>Usuario:</strong> <br></br> {viaje.nombre}</p>
              <p><strong className='titles'>Trayecto:</strong> <br></br> {viaje.trayecto}</p>
              <p><strong className='titles'>Fecha:</strong> <br></br>  {new Date(viaje.fecha_creacion).toLocaleString()}</p>
              <p>
              <strong className='titles' >Estado:</strong>   <br></br>
                <Tag color={viaje.is_delete === 1 ? 'green' : 'red'}>{viaje.is_delete === 1 ? 'Trayecto Activo' : 'Trayecto Inactivo'}</Tag>
                <Tag color={viaje.estado === 1 ? 'green' : 'red'}>{viaje.estado === 1 ? 'Trayecto abierto' : 'Trayecto cerrado'}</Tag>
              </p>
              <hr></hr>
              <Title level={2}>Vehículos cargados</Title>

              <Table
                columns={[
                  { title: 'Placa', dataIndex: 'matricula', key: 'matricula',  width: '12%' },
                  { title: 'Tipo', dataIndex: 'tipo_vehiculo', key: 'tipo_vehiculo',width: '12%'  },
                  {
                    title: 'Tarifa',
                    dataIndex: 'precio',
                    key: 'precio',
                    render: (precio) => formatNumber(precio),
                    width: '12%' 
                  },
                  {
                    title: '',
                    render: (text, record) => (
                      <div>
                             <Button
                        type="default"
                        icon={<PoweroffOutlined />}
                        onClick={() => handleDelete(record.id_vehiculo, record.id_viaje)}
                      >
                      </Button>
                      <br></br>
                      <br></br>
                      { record.foto != null  && (
                          <Button
                          icon={<FileImageOutlined />}
                          onClick={() => showVehicleInfo(record.id_vehiculo)} // Mostrar detalles del vehículo
                        >
                          
                        </Button>)
                  }
                      </div>
                 


                    ),
                    
                  },
                ]}
                dataSource={vehiculos}
                pagination={false}
                scroll={{ x: 200 }}
                style={{ marginBottom: '20px' }}  // Reducir margen de la tabla
                footer={() => {
                  let total = 0;
                  vehiculos.forEach(row => {
                    total += Number(row.precio);
                  });
                 
                  // const totalTarifa = vehiculos.reduce((acc, vehiculo) => acc + (parseFloat(vehiculo.precio) || 0), 0);
                  const totalFormatted = formatNumber(total);
                  console.log(totalFormatted,"totalFormatted");
                  return (
                    <tr>
                      <td colSpan={3}><strong className='titles'>Recaudo actual trayecto: {"\u00A0"} </strong>  </td>
                      <td><strong className='stilosTotal'>{totalFormatted}</strong></td>
                    </tr>
                  );
                }}
              />
              <div>
             
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => navigate(`/facturacion/${viaje.id_viaje}/${viaje.id_trayecto}`)}
                  disabled={viaje.estado === 0}  // Deshabilitar si el estado es 0
                  style={{ marginBottom: '10px',width:365,height:60 }}
                >
                  Registrar vehículo
                </Button>
                <Button
                  type="primary"
                  icon={<PoweroffOutlined />}
                  onClick={() => handleDeactivate(viaje)}
                  style={{ marginBottom: '10px',width:365,height:60 }}
                  disabled={viaje.estado === 0}
                >
                  Cerrar trayecto
                </Button>

                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  onClick={() => recargarPagina()}  // Deshabilitar si el estado es 0
                  style={{ marginBottom: '10px',width:365,height:60 }}
                >
                 Actualizar
                </Button>
    
                {viaje.is_delete != undefined }
              <Button
                type="primary"
                icon={<PoweroffOutlined />}
                style={{ marginBottom: '10px',width:365 ,height:60}}
                onClick={() => handleDeleteTrayecto(viaje)}
              >
              { viaje.is_delete == 1 ? 'Desactivar trayecto':'Activar trayecto'} 
              </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

       {/* Modal de detalles del vehículo */}
       <Modal
        title="Información del Vehículo"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <img
          src={vehicleInfo && vehicleInfo.foto ? `${API_BASE_URL}/uploads/${vehicleInfo.foto}` : 'assets/course-default.jpg'}
          alt="Imagen del vehículo" width={200}
        />
        <p><strong>Matricula:</strong> {vehicleInfo ? vehicleInfo.matricula : 'N/A'}</p>
        <p><strong>Tipo de Vehículo:</strong> {vehicleInfo ? vehicleInfo.tipo_vehiculo : 'N/A'}</p>
        <p><strong>Conductor:</strong> {vehicleInfo ? vehicleInfo.nombre : 'N/A'}</p>
        <p><strong>Telefono:</strong> {vehicleInfo ? vehicleInfo.telefono : 'N/A'}</p>
        <p><strong>Fecha de registro:</strong> {vehicleInfo ? vehicleInfo.vehiculo_fecha_creacion : 'N/A'}</p>
      </Modal>
    </div>
  );
}

export default OpenTrayecto;
