import React, { useState, useEffect } from 'react';
import { Card, Table, Modal, Form, Input, Button, Select, message, Tag } from 'antd';
import { CheckCircleOutlined, ClearOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import API_BASE_URL from '../components/apiService';

const AdminUsuarios = () => {
  // Estado para manejar los usuarios y el formulario
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false); // Modal para cambiar la contraseña
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [total, setTotal] = useState(0); // Total de usuarios
  const [selectedUser, setSelectedUser] = useState(null); // Usuario seleccionado para cambiar la contraseña

  // Estado para el formulario
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm(); // Formulario para la contraseña

  // Función para obtener el token del localStorage
  const getAuthToken = () => {
    return localStorage.getItem('token');
  };

  // Cargar los usuarios desde la API al iniciar el componente o al cambiar la página
  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  useEffect(() => {
    passwordForm.resetFields();
    if (selectedUser) {
      form.setFieldsValue({
        nombre: selectedUser.nombre,
        usuario: selectedUser.usuario,
      });
    }
  }, [selectedUser]); // Esto se ejecutará cada vez que se cambie `selectedUser`
  

  
  // Función para obtener los usuarios con paginación
  const fetchUsers = async () => {
    const token = getAuthToken();

    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/user/all`, {
        headers: {
          Authorization: `${token}`, // Pasar el token en el header
        },
        params: {
          page: currentPage, // Enviar el número de página
          limit: 10, // Limitar los resultados a 10 por página
        },
      });

      const { Users } = response.data.data;
      setUsers(Users);
      setTotal(Users.length); // Debes actualizarlo con el valor total que te devuelva la API si es necesario
    } catch (error) {
      message.error('Error al cargar los usuarios');
    } finally {
      setLoading(false);
    }
  };

  // Función para mostrar el modal de cambiar contraseña
  const showPasswordModal = (user) => {
    setSelectedUser(user); // Guardar el usuario seleccionado
    setIsPasswordModalVisible(true);
  };

  // Función para cerrar el modal de cambiar contraseña
  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
    passwordForm.resetFields();
  };

  // Función para cambiar la contraseña
  const handleChangePassword = async (values) => {
    setLoading(true);
    try {
      const token = getAuthToken();
      const response = await axios.post(
        `${API_BASE_URL}/user/change-password`,
        {
          id_usuario: selectedUser.id_usuario,
          password: values.password,
          nombre:values.nombre,
          usuario:values.usuario
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        message.success('Contraseña actualizada correctamente');
        setIsPasswordModalVisible(false);
        fetchUsers(); // Recargar la lista de usuarios
        passwordForm.resetFields();
      } else {
        message.error('Error al cambiar la contraseña');
      }
    } catch (error) {
      message.error('Error al cambiar la contraseña');
    } finally {
      setLoading(false);
    }
  };
  // Función para mostrar el modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Función para cerrar el modal
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  // Función para crear un nuevo usuario
  const handleCreate = async (values) => {
    setLoading(true);
    try {
      const token = getAuthToken();
      const response = await axios.post(`${API_BASE_URL}/user`, values, {
        headers: {
          Authorization: `${token}`,
        },
      });

      // Verifica que la respuesta tenga éxito
      if (response.data.success) {
        message.success('Usuario creado correctamente');
        setIsModalVisible(false);
        fetchUsers(); // Recargar la lista de usuarios
        form.resetFields();
      } else {
        message.error('Error al crear el usuario');
      }
    } catch (error) {
      message.error('Error al crear el usuario');
    } finally {
      setLoading(false);
    }
  };

  // Función para cambiar el estado del usuario (Activo/Inactivo)
  const toggleUserStatus = async (id_usuario, currentStatus) => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      // Alternar el estado entre 1 (Activo) y 0 (Inactivo)
      const newStatus = currentStatus === 1 ? 0 : 1;

      // Realizar la solicitud POST para cambiar el estado
      const response = await axios.post(
        `${API_BASE_URL}/admin/usuario/status`,
        { id_usuario, estado: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        message.success(`El usuario ha sido marcado como ${newStatus === 1 ? 'Activo' : 'Inactivo'}`);
        fetchUsers(); // Recargar la lista de usuarios después de cambiar el estado
      } else {
        message.error('Error al actualizar el estado del usuario');
      }
    } catch (error) {
      message.error('Error al actualizar el estado del usuario');
    } finally {
      setLoading(false);
    }
  };

  // Columnas para la tabla
  const columns = [
    {
      title: 'Acción',
      key: 'action',
      render: (_, record) => (
        <>
          <Button
            type="default"
            onClick={() => showPasswordModal(record)}  // Mostrar modal para cambiar contraseña
            icon={<EditOutlined></EditOutlined>}
            style={{ marginRight: 8 }}
          >
          </Button>
          <Button
            type="default"
            icon={record.estado === 1 ? <ClearOutlined /> : <ClearOutlined />}
            // style={{
            //   backgroundColor: record.estado === 1 ? '#f5222d' : '#52c41a',
            //   borderColor: record.estado === 1 ? '#f5222d' : '#52c41a',
            // }}
            onClick={() => toggleUserStatus(record.id_usuario, record.estado)}
          >
            {record.estado === 1 ? 'Inactivar' : 'Activar'}
          </Button>
        </>
      ),
    },
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { 
      title: 'Correo', 
      dataIndex: 'usuario', 
      key: 'usuario', 
      ellipsis: true, // Hace que el texto largo se corte
    },
    { 
      title: 'Fecha de Creación', 
      dataIndex: 'fecha_creacion', 
      key: 'fecha_creacion', 
      responsive: ['md'], // Solo mostrar en pantallas medianas o más grandes
    },
    { 
      title: 'Perfil', 
      dataIndex: 'perfil_id', 
      key: 'perfil_id', 
      render: (perfil) => (perfil === 1 ? 'Admin' : 'User'),
      responsive: ['md'], // Solo mostrar en pantallas medianas o más grandes
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (_, record) => (
        <Tag color={record.estado === 1 ? 'green' : 'red'}>
          {record.estado === 1 ? 'Activo' : 'Inactivo'}
        </Tag>
      ),
    },
   
  ];

  // Función para manejar el cambio de página
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card title="Administración de Usuarios">
      <Button type="primary" onClick={showModal} style={{ marginBottom: 20 }}>
        Crear Nuevo Usuario
      </Button>

      <Table
        columns={columns}
        dataSource={users}
        rowKey="id_usuario"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: 10,
          total,
          onChange: handlePageChange,
        }}
        responsive // Esta propiedad hace que la tabla sea responsiva
        scroll={{ x: 'max-content' }} // Permite el desplazamiento horizontal si la tabla es demasiado ancha
      />

      {/* Modal modificar el usuario */}
      <Modal
      key={selectedUser?.id_usuario} // Forzar el modal a renderizar de nuevo cada vez que cambie el usuario seleccionado
      title="Modificar usuario"
      visible={isPasswordModalVisible}
      onCancel={handlePasswordModalCancel}
      footer={null}
>
  <Form
    form={passwordForm}
    onFinish={handleChangePassword}
    layout="vertical"
    initialValues={{
      nombre: selectedUser?.nombre, // Establece el valor del nombre
      usuario: selectedUser?.usuario, // Establece el valor del correo electrónico
    }}
  >
    <Form.Item
      name="nombre"
      label="Nombre"
      rules={[{ required: true, message: 'Por favor ingresa el nombre' }]} >
      <Input />
    </Form.Item>

    <Form.Item
      name="usuario"
      label="Correo Electrónico"
      rules={[{ required: true, message: 'Por favor ingresa un correo electrónico' }, { type: 'email', message: 'El correo electrónico no es válido' }]} >
      <Input />
    </Form.Item>

    <Form.Item
      name="password"
      label="Contraseña"
      rules={[{ required: true, message: 'Por favor ingresa una nueva contraseña' },
        { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]}>
      <Input.Password />
    </Form.Item>

    <Form.Item
      name="confirmPassword"
      label="Confirmar Contraseña"
      dependencies={['password']}
      rules={[
        { required: true, message: 'Por favor confirma la contraseña' },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject('Las contraseñas no coinciden');
          },
        }),
      ]}>
      <Input.Password />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block>
        Cambiar Contraseña
      </Button>
    </Form.Item>
  </Form>
</Modal>

      {/* Modal para crear un usuario */}
      <Modal
        title="Crear Usuario"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreate}
          layout="vertical"
          initialValues={{ perfil: 'User' }} // Perfil por defecto
        >
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: 'Por favor ingresa el nombre' }]} >
            <Input />
          </Form.Item>

          <Form.Item
            name="usuario"
            label="Correo Electrónico"
            rules={[{ required: true, message: 'Por favor ingresa un correo electrónico' }, { type: 'email', message: 'El correo electrónico no es válido' }]} >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Contraseña"
            rules={[{ required: true, message: 'Por favor ingresa una contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirmar Contraseña"
            dependencies={['password']}
            rules={[{ required: true, message: 'Por favor confirma la contraseña' }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Las contraseñas no coinciden');
              },
            })]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="perfil_id"
            label="Perfil"
            rules={[{ required: true, message: 'Por favor selecciona el perfil' }]} >
            <Select>
              <Select.Option value="2">User</Select.Option>
              <Select.Option value="1">Admin</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Crear Usuario
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default AdminUsuarios;
