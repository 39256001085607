import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';  
import { message, Row, Col, Typography, Card, Divider, Space, Button } from 'antd';
import { DollarCircleOutlined, CalendarOutlined, PhoneOutlined, UserOutlined, PrinterOutlined, CloseOutlined } from '@ant-design/icons';
import API_BASE_URL from '../components/apiService';
import bluetoothPrinterService from '../components/BluetoothPrinterService';


const { Title, Text } = Typography;

const InvoiceDetail = () => {
  const { id } = useParams();  
  const navigate = useNavigate();  
  const [facturaData, setFacturaData] = useState(null);  

  // Función para obtener los detalles de la factura
  const fetchFacturaData = async (id) => {
    try {
      const token = localStorage.getItem('token');  
      if (!token) {
        message.error('Token no encontrado.');
        return;
      }

      const response = await fetch(`${API_BASE_URL}/operator/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
        },
      });

      const result = await response.json();
      if (result.success) {
        setFacturaData(result.data.factura);  
      } else {
        message.error('No se pudo obtener la factura.');
      }
    } catch (error) {
      console.error('Error al obtener los datos de la factura:', error);
      message.error('Error al obtener los datos de la factura');
    }
  };

  useEffect(() => {
    if (id) {
      fetchFacturaData(id);  
    }
  }, [id]);

  // Formateo de la fecha
  const formattedDate = new Date(facturaData?.fecha_factura).toLocaleDateString('es-CO', {
    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'
  });

  // Formato para mostrar los números como moneda
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  // Función para imprimir la factura usando el servicio Bluetooth
  const handlePrint = async () => {
    try {
      const isConnected = await bluetoothPrinterService.connectToPrinter(); // Conectar a la impresora
      if (!isConnected) {
        message.error("No se pudo conectar a la impresora.");
        return;
      }

      // Preparar el texto de la factura para imprimir
      const printData = `
        Factura de Transporte
        ID: ${facturaData.id_factura}
        Fecha: ${formattedDate}
        Propietario: ${facturaData.propietario_nombre}
        Teléfono: ${facturaData.telefono}
        Cédula: ${facturaData.cedula}
        Matrícula: ${facturaData.matricula}
        Tipo de Vehículo: ${facturaData.tipo_vehiculo}
        Ferry: ${facturaData.ferry}

        Trayecto: ${facturaData.trayecto}
        Tarifa: ${formatCurrency(facturaData.tarifa)}
        
        Total: ${formatCurrency(facturaData.tarifa)}

        ¡Gracias por utilizar nuestros servicios!`;

      // Imprimir el texto
      await bluetoothPrinterService.printData(printData);
      bluetoothPrinterService.disconnectPrinter();  // Desconectar después de imprimir
      message.success("Factura impresa correctamente.");
    } catch (error) {
      console.error('Error al imprimir:', error);
      message.error("Error al imprimir la factura.");
    }
  };

  // Función para cerrar y regresar
  const handleClose = () => {
     navigate(`/opentrayecto`);  // Regresa a la página anterior
  };

  // Verifica si los datos de la factura han sido cargados
  if (!facturaData) {
    return <div>Cargando detalles de la factura...</div>;
  }

  return (
    <div style={{ padding: '30px', maxWidth: '900px', margin: 'auto', backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: '10px' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Title level={1} style={{ fontSize: '15px', marginBottom: 1 }}>TRANSDIER SAS.</Title>
        <Title level={5} style={{ fontSize: '15px', marginBottom: 1 }}>NIT. 90184077-21</Title>
        <Title level={5} style={{ fontSize: '15px', marginBottom: 1 }}>CRA.10 N° 13-30 PIJAY-MAGD</Title>
        <Title level={5} style={{ fontSize: '15px', marginBottom: 1 }}>3007297949- 3005245822</Title>
        <Title level={5} style={{ fontSize: '15px', marginBottom: 1 }}>Factura de Transporte</Title>
        <Text strong style={{ fontSize: '15px' }}>TD{facturaData.id_factura}</Text>
      </div>

      {/* Encabezado de la factura */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={12}>
          <Text strong>Fecha de emisión:</Text> {formattedDate}<br />
          <Text strong><UserOutlined /> Propietario:</Text> {facturaData.propietario_nombre}<br />
          <Text strong><PhoneOutlined /> Teléfono:</Text> {facturaData.telefono}<br />
          <Text strong><CalendarOutlined /> Cédula:</Text> {facturaData.cedula}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text strong style={{ fontSize: '15px' }}>Matrícula: {facturaData.matricula}</Text><br />
          <Text strong>Tipo de Vehículo:</Text> {facturaData.tipo_vehiculo}<br />
          <Text strong>Ferry:</Text> {facturaData.ferry}
        </Col>
      </Row>

      <Divider />

      {/* Detalles del trayecto */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={24}>
          <Card title="Detalles del Trayecto" bordered={false} style={{ border: '1px solid #ddd' }}>
            <Text strong>Trayecto:</Text> {facturaData.trayecto}<br />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/* Información de la factura */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={12}>
          <Text strong><DollarCircleOutlined /> Tarifa:</Text> {formatCurrency(facturaData.tarifa)}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {/* <Text strong>IVA (19%):</Text> 0 */}
        </Col>
      </Row>

      <Divider />

      {/* Total */}
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={12}>
          <Text strong style={{ fontSize: '18px' }}>Total:</Text>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Text strong style={{ fontSize: '18px' }}>{formatCurrency(facturaData.tarifa)}</Text>
        </Col>
      </Row>

      <Divider />

      {/* Footer */}
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space direction="vertical">
            <Text type="secondary">Gracias por utilizar nuestros servicios. ¡Esperamos verte pronto!</Text>
          </Space>
        </Col>
      </Row>

      {/* Botones al final */}
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        {/* <Button
          icon={<PrinterOutlined />}
          type="primary"
          onClick={handlePrint}
          style={{ marginRight: '10px' }}
        >
          Imprimir
        </Button> */}
        <Button
          onClick={handleClose}
          icon={<CloseOutlined />}
          style={{ marginLeft: '10px' }}
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
};

export default InvoiceDetail;
