import React, { useState, useEffect } from 'react';
import { Card, Collapse, Pagination, Tag, Table, Button, message,Modal } from 'antd';
import axios from 'axios';
import API_BASE_URL from '../components/apiService';
import moment from 'moment';
import { FileImageOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';

const { Panel } = Collapse;

const Reportes = () => {
  const [gastosData, setGastosData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [detalleGastosData, setDetalleGastosData] = useState({});
  const [totalRecaudos, setTotalRecaudos] = useState({});
  const [vehiculos, setVehiculos] = useState({});  // Cambiado para almacenar vehículos por jornada/trayecto
  const [vehiculosCargados, setVehiculosCargados] = useState({});
  const [activeKey, setActiveKey] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const token = localStorage.getItem('token');
  const [modalVisible, setModalVisible] = useState(false); // Estado del modal
  const [vehiculosTotalDetalle, setVehiculosTotalDetalle] = useState([]);
    const [vehiculosTotalDetalleViaje, setVehiculosTotalDetalleViaje] = useState([]); // Nuevo estado para recaudo por usuario
  const fetchGastos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/admin/jornadas/all`,
        {
          params: {
            page: currentPage,
            limit: 30,
          },
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        setGastosData(response.data.data.jornadas);
        setTotalItems(response.data.data.count);
      } else {
        console.error('Error al obtener los datos de gastos');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud', error);
    } finally {
      setLoading(false);
    }
  };

  // Función para cargar los vehículos si no han sido cargados previamente
  const fetchVehiculos = async (id_viaje, trayecto_id) => {
    if (vehiculosCargados[`${id_viaje}_${trayecto_id}`]) {
      return; // No hacemos la solicitud si ya se han cargado los vehículos
    }
    fetchVehiculosDetalleByViajes(id_viaje)
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/viajes/vehiculos?id_viaje=${id_viaje}&trayectos_id=${trayecto_id}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (data.success) {
        setVehiculos(prevState => ({
          ...prevState,
          [`${id_viaje}_${trayecto_id}`]: data.data.viajes,  // Almacenamos los vehículos para el viaje y trayecto
        }));
        setVehiculosCargados(prev => ({
          ...prev,
          [`${id_viaje}_${trayecto_id}`]: true,
        }));
      } else {
        message.error('No se pudieron cargar los vehículos');
      }
    } catch (error) {
      console.error('Error al obtener vehículos:', error);
      message.error('Error al obtener los vehículos');
    }
  };
  const showVehicleInfo = async (vehiculo_id) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    setModalVisible(true);
    setVehicleInfo(null);
    try {
      // Realizar la consulta a la API para obtener la información del vehículo
      const response = await axios.get(
        `${API_BASE_URL}/admin/vehiculo/${vehiculo_id}`,
        {
          headers: {
            'Authorization': `${token}`  // Asegúrate de que el token esté correctamente formateado
          }
        }
      );

      // Almacenar la información del vehículo en el estado
      setVehicleInfo({
        ...response.data.data.vehiculo,
        imagen: response.data.imagen || 'https://via.placeholder.com/150' // Imagen de prueba si no se obtiene una
      });
    } catch (error) {
      console.error('Error al obtener la información del vehículo:', error);
      setVehicleInfo(null); // En caso de error, vaciar la información del vehículo
    } finally {
      setLoading(false);
    }
  };
  // Función para cargar los detalles de los gastos por fecha_ini
  const fetchDetalleGastos = async (fechaIni, idJornada) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://appmagdalena.net/transdier/admin/gastos/all`,
        {
          params: {
            page: 1,
            limit: 10000,
            fecha_ini: fechaIni,
          },
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        // const gastos = response.data.data.gastos;
        const ingresos = response.data.data.gastos.filter(gasto => gasto.tipo_operacion === 'INGRESO');
        setDetalleGastosData(prevState => ({
          ...prevState,
          [idJornada]: ingresos,
        }));

        let totalRecaudo = 0;
        ingresos.forEach(row => {
          // if (gasto.tipo_operacion === 'INGRESO') {
            totalRecaudo += Number(row.monto);
          // }
        });

        setTotalRecaudos(prevState => ({
          ...prevState,
          [idJornada]: totalRecaudo,
        }));

      } else {
        console.error('Error al obtener los detalles de los gastos');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGastos();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchGastos();
  };

  const handlePanelClick = (fechaInicio, idJornada, key) => {
    if (!detalleGastosData[idJornada]) {
      fetchDetalleGastos(fechaInicio, idJornada);
      fetchVehiculosDetalle(idJornada)
    }
    setActiveKey(key);
  };

  const formatMonto = (monto) => {
    return new Intl.NumberFormat('es-ES').format(monto);
  };

  const formatFecha = (fecha) => {
    return moment(fecha).format('DD/MM/YYYY');
  };
  const fetchVehiculosDetalle = async (id_jornada) => {
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/vehiculos/detaills/all?id_jornada=${id_jornada}`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id_jornada: id_jornada,
        },
      });

      // El formato de respuesta esperado es un array de objetos con nombre y total
      setVehiculosTotalDetalle(response.data.data.details);  // Guardar el recaudo por usuario
    } catch (error) {
      message.error('Error al cargar los recaudos de los usuarios');
    }
  };
  const fetchVehiculosDetalleByViajes = async (id_viaje) => {
    if(vehiculosTotalDetalleViaje.length > 0) return;
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/vehiculos/detaills/viaje/all`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          id_viaje: id_viaje,
        },
      });

      // El formato de respuesta esperado es un array de objetos con nombre y total
      setVehiculosTotalDetalleViaje(response.data.data.details);  // Guardar el recaudo por usuario
    } catch (error) {
      message.error('Error al cargar los recaudos de los usuarios');
    }
  };
  const expandedRowRender = (record) => {
    // Llamar a fetchVehiculos solo si no se han cargado antes
    fetchVehiculos(record.id_viaje, record.id_trayecto);
    // 
    const vehiculosForPanel = vehiculos[`${record.id_viaje}_${record.id_trayecto}`] || [];  // Tomamos los vehículos de este panel específico

    return (
      <>
       <Table
        dataSource={vehiculosForPanel}
        rowKey="id_viaje"
        pagination={false}
        columns={[
          {
            title: 'Placa',
            dataIndex: 'matricula',
            key: 'matricula',
            render: (text, record) => (
              record.foto !== null ? (
                <Button
                  icon={<FileImageOutlined />}
                  onClick={() => showVehicleInfo(record.id_vehiculo)} // Mostrar detalles del vehículo
                >
                  {text}
                </Button>
              ) : (
                text // Si no hay foto, solo mostramos el texto
              )
            ),
          },
          { 
            title: 'Tarifa', 
            dataIndex: 'precio', 
            key: 'precio', 
            render: (text) => formatMonto(text) 
          },
          { 
            title: 'Tipo', 
            dataIndex: 'tipo_vehiculo', 
            key: 'tipo_vehiculo' 
          },
        ]}
      />

<Title level={5} style={{color:'red'}}>Cantidad Vehículos del trayecto</Title>

       <Table
        dataSource={vehiculosTotalDetalleViaje}
        rowKey="id_viaje"
        pagination={false}
        style={{width:'45%'}}
        columns={[
          { 
            title: 'Tipo', 
            dataIndex: 'tipo_vehiculo', 
            key: 'tipo_vehiculo' 
          },
          { 
            title: 'Cantidad', 
            dataIndex: 'cantidad', 
            key: 'cantidad' 
          },
        ]}
      />
      </>
     
    );
  };

  return (
    <div>
      <Card title="Historial de jornadas">
        <Collapse activeKey={activeKey} onChange={key => setActiveKey(key)}>
          {gastosData.map((gasto, index) => (
            <Panel
              header={`Jornada: (J${gasto.id_jornada}) ${formatFecha(gasto.fecha_inicio)}`}
              key={gasto.id_jornada}
              onClick={() => handlePanelClick(gasto.fecha_inicio, gasto.id_jornada, gasto.id_jornada)}
              style={{
                background: 'linear-gradient(135deg, #1a68e8, #1e88e5)', // Gradiente para un fondo más dinámico
               
                borderRadius: '8px',  // Bordes redondeados para mayor suavidad
                marginBottom: '10px',  // Espacio entre los panels
                padding: '10px',  // Espaciado interno del panel
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Sombra para dar profundidad
                transition: 'all 0.3s ease',  // Transición suave para cambios de estilo
              }}
              headerStyle={{
                fontSize: '16px',  // Tamaño de fuente del encabezado
                fontWeight: 'bold',  // Negrita en el encabezado para darle importancia
                textTransform: 'uppercase',  // Texto en mayúsculas para un estilo más impactante
                letterSpacing: '0.5px',  // Espaciado entre letras para mejorar la legibilidad
               
              }}
            >
              <p><strong className='titles'>Ferry:</strong> {gasto.ferry_nombre}</p>
              <p><strong className='titles'>Usuario:</strong> {gasto.usuario_nombre}</p>
              <p><strong className='titles'>Recaudo Total:</strong> {formatMonto(gasto.total_recaudo)}</p>
              <p><strong className='titles'>Estado: </strong>
                <Tag color={gasto.estado === 1 ? 'green' : 'red'}>
                  {gasto.estado === 1 ? 'Jornada Abierta' : 'Jornada Cerrada'}
                </Tag>
              </p>

              {detalleGastosData[gasto.id_jornada] && (
                <>
                <Card  title="Trayectos Realizados" style={{ marginTop: 20 }}>
                  <Table
                    columns={[
                      { title: 'Trayecto y usuario', dataIndex: 'trayecto', key: 'trayecto',
                        render: (text) => <span style={{ color: '#1b6ce8', fontWeight: 'bold' }}>{text}</span>, // Color al contenido
                       },
                      { title: 'Recaudo', dataIndex: 'monto', key: 'monto', render: (text) => <span style={{ color: '#18ca29', fontWeight: 'bold' }}>{formatMonto(text)}</span> },
                      { title: 'Vehiculos', dataIndex: 'vehiculos', key: 'vehiculos' },
                      { title: 'Fecha', dataIndex: 'fecha_creacion', key: 'fecha_creacion' },
                    ]}
                    dataSource={detalleGastosData[gasto.id_jornada]}
                    rowKey="id_viaje"
                    pagination={false}
                    expandedRowRender={expandedRowRender} // Añadir la función de expansión
                    loading={loading}
                    scroll={{ x: 'max-content' }}
                    
                  />
                
                  
                </Card>
                <Card title="VEHÍCULOS DE LA JORNADA"  style={{ marginTop: 20, color:'red' }}>

                        <Table
                            columns={
                              [
                                {
                                  title: 'Vehículo',
                                  dataIndex: 'tipo_vehiculo',
                                  key: 'tipo_vehiculo',
                                },
                                {
                                  title: 'Cantidad',
                                  dataIndex: 'cantidad',
                                  key: 'cantidad',
                                }
                              ]
                            }
                            dataSource={vehiculosTotalDetalle}
                            rowKey="id_tipo_vehiculo"
                            loading={loading} // Mostrar carga mientras se obtienen los datos
                            scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
                            responsive={true}  // Habilita la responsividad de la tabla
                            pagination={false} // Desactivar la paginación
                          />
                        <Table
                            columns={
                              [
                                {
                                  title: 'Total',
                                  dataIndex: 'total',
                                  key: 'total',
                  
                                }
                              ]
                            }
                            dataSource={vehiculosTotalDetalle.slice(0, 1)}
                            rowKey="id_tipo_vehiculo"
                            loading={loading} // Mostrar carga mientras se obtienen los datos
                            scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
                            responsive={true}  // Habilita la responsividad de la tabla
                            pagination={false} // Desactivar la paginación
                          />
                </Card>
                </>
              )}
            </Panel>
          ))}
        </Collapse>

        <div style={{ marginTop: 20 }}>
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={10}
            onChange={handlePageChange}
          />
        </div>
      </Card>

        {/* Modal de detalles del vehículo */}
        <Modal
        title="Información del Vehículo"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <img
          src={vehicleInfo && vehicleInfo.foto ? `${API_BASE_URL}/uploads/${vehicleInfo.foto}` : 'assets/course-default.jpg'}
          alt="Imagen del vehículo" width={350}
        />
        <p><strong>Matricula:</strong> {vehicleInfo ? vehicleInfo.matricula : 'N/A'}</p>
        <p><strong>Tipo de Vehículo:</strong> {vehicleInfo ? vehicleInfo.tipo_vehiculo : 'N/A'}</p>
        <p><strong>Conductor:</strong> {vehicleInfo ? vehicleInfo.nombre : 'N/A'}</p>
        <p><strong>Telefono:</strong> {vehicleInfo ? vehicleInfo.telefono : 'N/A'}</p>
        <p><strong>Fecha de registro:</strong> {vehicleInfo ? vehicleInfo.vehiculo_fecha_creacion : 'N/A'}</p>
      </Modal>
    </div>
  );
};

export default Reportes;
