import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Modal, Form, Input, message, Tabs } from 'antd';
import axios from 'axios';
import { NumericFormat } from 'react-number-format'; 
import API_BASE_URL from '../components/apiService';
import BluetoothPrinterConnect from './BluetoothPrinter';
import { EditOutlined } from '@ant-design/icons';

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const Configuraciones = () => {
  const [tiposVehiculos, setTiposVehiculos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);  // Nuevo estado para el modal de edición
  const [form] = Form.useForm();
  const [selectedTipoVehiculo, setSelectedTipoVehiculo] = useState(null);

  const fetchTiposVehiculos = async () => {
    const token = getAuthToken();
    if (!token) {
      message.error('No estás autenticado');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`${API_BASE_URL}/admin/tipovehiculo/all`, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          page: currentPage,
          limit: 1000,
        },
      });

      const { count, tipovehiculo } = response.data.data;
      setTiposVehiculos(tipovehiculo);
      setTotal(count);
    } catch (error) {
      message.error('Error al cargar los tipos de vehículos');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTiposVehiculos();
  };

  useEffect(() => {
    fetchTiposVehiculos();
  }, [currentPage]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);  // Cerrar el modal de edición
    form.resetFields();
  };

  const handleCreate = async (values) => {
    setLoading(true);
    const token = getAuthToken();

    const tarifa = values.tarifa.replace(/[^0-9.-]+/g, '');

    try {
      await axios.post(`${API_BASE_URL}/admin/tipovehiculo`, {
        tipo_vehiculo: values.tipo_vehiculo,
        tarifa: parseFloat(tarifa),
      }, {
        headers: {
          Authorization: `${token}`,
        },
      });

      message.success('Tipo de vehículo creado correctamente');
      setIsModalOpen(false);
      fetchTiposVehiculos();
      form.resetFields();
    } catch (error) {
      message.error('Error al crear el tipo de vehículo');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (values) => {
    setLoading(true);
    const token = getAuthToken();

    const tarifa = values.tarifa.replace(/[^0-9.-]+/g, '');
        
    try {
      await axios.post(`${API_BASE_URL}/admin/tipovehiculo/tarifa`, {
        
          id_tipo_vehiculo:selectedTipoVehiculo.id_tipo_vehiculo,
          tarifa:tarifa,
          tipo_vehiculo:values.tipo_vehiculo
        
      }, {
        headers: {
          Authorization: `${token}`,
        },
        // params:{
        //   id_tipo_vehiculo:selectedTipoVehiculo.id_tipo_vehiculo,
        //   tarifa:selectedTipoVehiculo.tarifa,
        //   tipo_vehiculo:selectedTipoVehiculo.tipo_vehiculo
        // }
      });

      message.success('Tipo de vehículo actualizado correctamente');
      setIsEditModalOpen(false);
      fetchTiposVehiculos();
      form.resetFields();
    } catch (error) {
      message.error('Error al actualizar el tipo de vehículo');
    } finally {
      setLoading(false);
    }
  };

  const showModalEdit = (data) => {
    setSelectedTipoVehiculo(data);  // Guardamos los datos del tipo de vehículo seleccionado
    form.setFieldsValue({
      tipo_vehiculo: data.tipo_vehiculo,
      tarifa: data.tarifa,
    });
    setIsEditModalOpen(true);  // Abrimos el modal de edición
  };

  const columns = [
    {
      title: 'Acción',
      key: 'action',
      width: '2%',
      render: (_, record) => (
        <Button
          type="default"
          onClick={() => showModalEdit(record)}
          icon={<EditOutlined />}
          style={{ marginRight: 8 }}
        >
        </Button>
      ),
    },
    { title: 'Tipo de Vehículo', dataIndex: 'tipo_vehiculo', key: 'tipo_vehiculo', width: '12%' },
    {
      title: 'Tarifa',
      dataIndex: 'tarifa',
      width: '18%',
      key: 'tarifa',
      render: (tarifa) => (
        <NumericFormat
          value={tarifa}
          displayType="text"
          thousandSeparator={true}
          decimalScale={0}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      ),
    },
    { title: 'Usuario', dataIndex: 'usuario_nombre', key: 'usuario_nombre', width: '10%' },
    { title: 'Fecha de Creación', dataIndex: 'fecha_crea', key: 'fecha_crea' },
  ];

  const tabItems = [
    {
      key: "1",
      label: "Tipos de Vehículos",
      children: (
        <>
          <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: 20 }}>
            Crear Nuevo Tipo de Vehículo
          </Button>

          <Table
            columns={columns}
            dataSource={tiposVehiculos}
            rowKey="id_tipo_vehiculo"
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize: 1000,
              total,
              onChange: handlePageChange,
            }}
            scroll={{ x: 'max-content' }}
            responsive
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Configuración Cámaras",
      children: <BluetoothPrinterConnect />
    },
    {
      key: "3",
      label: "Configuración Sensores",
      children: <p>Aquí irían otras configuraciones.</p>
    },
  ];

  return (
    <Card title="Configuraciones">
      <Tabs defaultActiveKey="1" items={tabItems} />
      
      {/* Modal para agregar nuevo tipo de vehículo */}
      <Modal
        title="Crear Nuevo Tipo de Vehículo"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreate}
        >
          <Form.Item
            name="tipo_vehiculo"
            label="Tipo de Vehículo"
            rules={[{ required: true, message: 'Por favor ingresa el tipo de vehículo' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tarifa"
            label="Tarifa"
            rules={[{ required: true, message: 'Por favor ingresa la tarifa' }]}
          >
            <NumericFormat
              customInput={Input}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
            />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            Crear Tipo de Vehículo
          </Button>
        </Form>
      </Modal>

      {/* Modal para editar tipo de vehículo */}
      <Modal
        title="Editar Tipo de Vehículo"
        open={isEditModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEdit}
        >
          <Form.Item
            name="tipo_vehiculo"
            label="Tipo de Vehículo"
            rules={[{ required: true, message: 'Por favor ingresa el tipo de vehículo' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tarifa"
            label="Tarifa"
            rules={[{ required: true, message: 'Por favor ingresa la tarifa' }]}
          >
            <NumericFormat
              customInput={Input}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
            />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            Actualizar Tipo de Vehículo
          </Button>
        </Form>
      </Modal>
    </Card>
  );
};

export default Configuraciones;
