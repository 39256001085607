import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Table, message, Select } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'; // Importamos el ícono de editar
import API_BASE_URL from '../components/apiService';
import { Option } from 'antd/es/mentions';

const PlacasExoneradas = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false); // Para el modal de edición
  const [form] = Form.useForm();
  const [placas, setPlacas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipoVehiculo, setTipoVehiculo] = useState('');
  const [tiposVehiculos, setTiposVehiculos] = useState([]);
  const [selectedPlaca, setSelectedPlaca] = useState(null); // Para almacenar la placa seleccionada en el modal de edición
  const [searchText, setSearchText] = useState(''); // Estado para el filtro de búsqueda

  // Función para abrir el modal de creación
  const showModal = () => {
    setIsModalVisible(true);
    setSelectedPlaca(null);
  };

  // Función para abrir el modal de edición
  const showModalEdit = (record) => {
    setSelectedPlaca(record); // Guardamos los datos de la placa seleccionada
    setIsEditModalVisible(true); // Abrimos el modal de edición
    form.setFieldsValue({
      matricula: record.matricula,
      descripcion: record.descripcion,
      tipoVehiculo: record.tipo_vehiculo_id,
    }); // Prellenamos el formulario con los datos de la placa seleccionada
  };

  // Función para cerrar el modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditModalVisible(false);
    form.resetFields(); // Limpiar los campos al cerrar el modal
  };

  // Función para manejar el envío del formulario (crear o editar placa)
  const handleOk = async () => {
    try {
      // Validar los campos
      const values = await form.validateFields();
      const placaData = {
        matricula: values.matricula,
        descripcion: values.descripcion,
        tipo_vehiculo: values.tipoVehiculo,
      };

      if (selectedPlaca) {
        // Si estamos editando, actualizamos la placa
        placaData.id_matricula = selectedPlaca.id_matricula; // Agregamos el ID de la placa para la actualización
        await handleApiCall('update', placaData); // Llamamos a la API para actualizar
      } else {
        // Si estamos creando, llamamos a la API para crear la placa
        await handleApiCall('create', placaData);
      }

      // Cerrar el modal
      setIsModalVisible(false);
      setIsEditModalVisible(false);
      form.resetFields(); // Limpiar el formulario
    } catch (error) {
      message.error('Por favor ingrese todos los campos correctamente');
    }
  };

  // Función para hacer la llamada a la API (crear o editar)
  const handleApiCall = async (action, placaData) => {
    setLoading(true);
    const token = localStorage.getItem('token'); // Obtener el token desde localStorage
    if (!token) {
      message.error('Token no encontrado');
      setLoading(false);
      return;
    }

    try {
      let response;
      if (action === 'create') {
        // Crear placa
        response = await fetch(`${API_BASE_URL}/admin/exonerada`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify(placaData),
        });
      } else if (action === 'update') {
        // Actualizar placa
        response = await fetch(`${API_BASE_URL}/admin/exonerada/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify(placaData),
        });
      }

      const data = await response.json();
      if (data.success) {
        message.success('Placa guardada exitosamente');
        fetchPlacas(); // Recargamos la lista de placas
      } else {
        message.error(data.error.message);
      }
    } catch (error) {
      message.error('Error al procesar la placa');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener las placas
  const fetchPlacas = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
      message.error('Token no encontrado');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/exoneradas/all`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setPlacas(data.data.matriculas);
      } else {
        message.error('Error al cargar las placas');
      }
    } catch (error) {
      message.error('Error al obtener las placas');
    } finally {
      setLoading(false);
    }
  };

  // Función para eliminar una placa
  const handleDelete = async (id_matricula) => {
    const token = localStorage.getItem('token');
    if (!token) {
      message.error('Token no encontrado');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/exonerada/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({ id_matricula }),
      });
      const data = await response.json();
      if (data.success) {
        message.success('Placa eliminada exitosamente');
        setPlacas(placas.filter((placa) => placa.id_matricula !== id_matricula));
        fetchPlacas();
      } else {
        message.error('Error al eliminar la placa');
      }
    } catch (error) {
      message.error('Error al eliminar la placa');
    }
  };

  // Función para obtener los tipos de vehículos
  const fetchTiposVehiculos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/vehicletype`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.success) {
        setTiposVehiculos(data.data.tipovehiculo);
      } else {
        message.error('No se pudieron cargar los tipos de vehículos');
      }
    } catch (error) {
      console.error('Error al obtener tipos de vehículos:', error);
      message.error('Error al obtener los tipos de vehículos');
    }
  };

  // Función para manejar el cambio en el campo de búsqueda
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Filtrar las placas basadas en el texto de búsqueda
  // const filteredPlacas = placas.filter((placa) =>
  //   placa.matricula.toLowerCase().includes(searchText.toLowerCase())
  // );

  // Filtrar las placas basadas en el texto de búsqueda y en el tipo de vehículo que no contiene "Cargada" ni "Cargado"
const filteredPlacas = placas
.filter((placa) =>
  placa.matricula.toLowerCase().includes(searchText.toLowerCase()) && 
  !placa.tipo_vehiculo.toLowerCase().includes("cargada") &&
  !placa.tipo_vehiculo.toLowerCase().includes("cargado")
);


  // Usamos useEffect para obtener las placas al cargar el componente
  useEffect(() => {
    fetchPlacas();
    fetchTiposVehiculos();
  }, []);

  // Definir las columnas de la tabla
  const columns = [
    {
      title: 'Acción',
      key: 'action',
      render: (_, record) => (
        <>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => showModalEdit(record)}  // Mostrar modal de edición
            style={{ marginRight: 8 }}
          />
          <Button
            type="default"
            icon={<DeleteOutlined />}
            loading={loading}
            onClick={() => handleDelete(record.id_matricula)} // Eliminar placa
          />
        </>
      ),
    },
    {
      title: 'Matrícula',
      dataIndex: 'matricula',
      key: 'matricula',
    },
    {
      title: 'Clasificación',
      dataIndex: 'tipo_vehiculo',
      key: 'tipo_vehiculo',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];

  return (
    <div>
      
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Agregar Placa
      </Button>
      <br></br>
      <br></br>
      {/* Campo de búsqueda para las matrículas */}
      <Input
        placeholder="Buscar matrícula"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: '300px' }}
      />


      <Table
        columns={columns}
        dataSource={filteredPlacas} // Usamos las placas filtradas
        pagination={false}
        style={{ marginTop: '20px' }}
        scroll={{ x: 'max-content' }}
        responsive={true}
      />

      {/* Modal para agregar o editar placa */}
      <Modal
        title={selectedPlaca ? "Editar Placa Exonerada" : "Agregar Placa Exonerada"}
        visible={isModalVisible || isEditModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="placaForm"
          initialValues={{ matricula: '', descripcion: '' }}
        >
          <Form.Item
            label="Matrícula"
            name="matricula"
            rules={[{ required: true, message: 'Por favor ingrese la matrícula' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de Vehículo"
            name="tipoVehiculo"
            rules={[{ required: true, message: 'Por favor seleccione el tipo de vehículo' }]}>
            <Select
              value={tipoVehiculo}
              onChange={setTipoVehiculo}
              placeholder="Seleccione el tipo de vehículo">
              {tiposVehiculos.map((vehiculo) => (
                <Option key={vehiculo.id_tipo_vehiculo} value={vehiculo.id_tipo_vehiculo}>
                  {vehiculo.tipo_vehiculo}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[{ required: false, message: 'Por favor ingrese la descripción' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PlacasExoneradas;
