import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, Select, message, Row, Col, Typography } from 'antd';
import Webcam from 'react-webcam';
import { CameraOutlined } from '@ant-design/icons';
import API_BASE_URL from '../components/apiService';
import { Modal } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
const { Title } = Typography;
const { Option } = Select;
function InvoiceForm() {

  const [matriculaSugerencias, setMatriculaSugerencias] = useState([]);
  const [loadingMatricula, setLoadingMatricula] = useState(false);
  const [jornada_id, setJornada_id] = useState();
  const [selectedTrayecto, setSelectedTrayecto] = useState('');
  const [cedula, setCedula] = useState('');
  const [nombreConductor, setNombreConductor] = useState('');
  const [telefonoConductor, setTelefonoConductor] = useState('');
  const [tipoVehiculo, setTipoVehiculo] = useState('');
  const [matriculaVehiculo, setMatriculaVehiculo] = useState('');
  const [matriculaRemolque, setMatriculaRemolque] = useState('');
  const [image, setImage] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [photoTaken, setPhotoTaken] = useState(false);
  const [trayectos, setTrayectos] = useState([]);
  const [tiposVehiculos, setTiposVehiculos] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [form] = Form.useForm();
  const webcamRef = useRef(null);
  const matriculaRef = useRef(null);
  const navigate = useNavigate();
  const { id,idtrayecto } = useParams();
  // Estado para el Modal
  // Función para capturar la foto
  const capturePhoto = () => {
    const screenshot = webcamRef.current.getScreenshot();
    setImage(screenshot);
    setPhotoTaken(true);
    setIsCameraOpen(false);
  };

  // Función para solicitar permisos de la cámara
  const requestCameraPermission = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      message.error('El navegador no soporta el acceso a la cámara.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      setIsCameraOpen(true);
    } catch (err) {
      console.error('Error al acceder a la cámara:', err);
      message.error('No se pudo acceder a la cámara. Verifique los permisos.');
    }
  };
// Dentro de tu componente
const filteredSugerencias = useMemo(() => {
  return matriculaSugerencias.filter(sug => sug.matricula.includes(matriculaVehiculo));
}, [matriculaSugerencias, matriculaVehiculo]);
  // Función para manejar el envío del formulario
  const handleSubmit = async (values) => {
    if (!tipoVehiculo || !matriculaVehiculo) {
      message.error('Por favor complete todos los campos obligatorios');
      return;
    }
    // Obtener la fecha actual en la zona horaria local
    const currentDate = new Date();

    // Restar un día si es necesario (esto se aplica sobre la fecha local)
    currentDate.setDate(currentDate.getDate());

    // Obtener el año, mes y día en formato local
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11
    const day = currentDate.getDate().toString().padStart(2, '0');

    // Formatear la fecha en formato YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;




    // Crear un objeto FormData para enviar los datos
    const formData = new FormData();
    formData.append('journey_id', jornada_id);
    formData.append('driver_name', nombreConductor);
    formData.append('driver_phone', telefonoConductor);
    formData.append('driver_id', cedula);
    formData.append('vehicle_type_id', tipoVehiculo);
    formData.append('vehicle_license', matriculaVehiculo);
    formData.append('trailer_license', matriculaRemolque || '');
    formData.append('fecha_crea', formattedDate);
    formData.append('id_viaje', id);
    formData.append('hora', new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })); // Hora actual
    formData.append('trayecto', selectedTrayecto);

    // Si se ha tomado una foto, añadirla al FormData
    if (image) {
      // Convertir la imagen a un archivo
      const byteString = atob(image.split(',')[1]);
      const mimeString = image.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const file = new Blob([uint8Array], { type: mimeString });
      formData.append('image', file, 'captura.jpg'); // Nombre del archivo
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error('Token no encontrado.');
        return;
      }

      // Aquí concatenamos la URL base con el endpoint "operator/"
      const apiUrl = `${API_BASE_URL}/operator/`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        message.success('Factura generada con éxito');
      
        console.log('Factura Generada:', result.data.response);
        navigate(`/facturadetails/${result.data.response}/${id}/${selectedTrayecto}`);
      } else {
        message.error(result.error.message);
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      message.error('Error al generar la factura');
    }
  };



  // Función para obtener los trayectos desde la API
  const fetchTrayectos = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/trayectos/all`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.success) {
        setTrayectos(data.data.trayectos);
      } else {
        message.error('No se pudieron cargar los trayectos');
      }
    } catch (error) {
      console.error('Error al obtener trayectos:', error);
      message.error('Error al obtener los trayectos');
    }
  };
  const buscarMatricula = async (value) => {
    if (value.length < 3) {
      // Limpiar sugerencias, pero no actualizar la matrícula
      setMatriculaSugerencias([]);
      return;
    }
  
    setLoadingMatricula(true);
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/operator/vehiculos/matriculas?matricula=${value}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.success) {
        if (data.data.viajes && data.data.viajes.length > 0) {
          setMatriculaSugerencias(data.data.viajes); // Mostrar sugerencias
        } else {
          setMatriculaSugerencias([]); // No hay sugerencias, limpiar
        }
      } else {
        setMatriculaSugerencias([]); // Si la API falla, limpiar sugerencias
        message.error('No se encontraron vehículos con esa matrícula');
      }
    } catch (error) {
      console.error('Error al buscar la matrícula:', error);
      setMatriculaSugerencias([]); // Limpiar sugerencias
      message.error('Error al buscar la matrícula');
    } finally {
      setLoadingMatricula(false);
    }
  };
  
  
  
// Función para obtener la jornada activa desde la API
const fetchJornadaActiva = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    message.error('No se encontró el token en el almacenamiento.');
    return;
  }

  try {
    const response = await fetch(`${API_BASE_URL}/operator/all`, {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();
    if (data.success) {
      // Asegurarse de que hay al menos una jornada activa
      const jornadaActiva = data.data.jorandas[0];  // Obtener la primera jornada activa
      if (jornadaActiva) {
        setJornada_id(jornadaActiva.id_jornada);  // Asignar el id_jornada
      } else {
        message.error('No se encontró una jornada activa');
      }
    } else {
      message.error('No se pudo obtener la jornada activa');
    }
  } catch (error) {
    console.error('Error al obtener jornada activa:', error);
    message.error('Error al obtener la jornada activa');
  }
};

  // Función para obtener los tipos de vehículos desde la API
  const fetchTiposVehiculos = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error('No se encontró el token en el almacenamiento.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/operator/vehicletype`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.success) {
        setTiposVehiculos(data.data.tipovehiculo);
      } else {
        message.error('No se pudieron cargar los tipos de vehículos');
      }
    } catch (error) {
      console.error('Error al obtener tipos de vehículos:', error);
      message.error('Error al obtener los tipos de vehículos');
    }
  };

  // Cargar los trayectos y tipos de vehículos cuando el componente se monte
  useEffect(() => {
    fetchTrayectos();
    fetchTiposVehiculos();
    fetchJornadaActiva();
  }, []);
  
  useEffect(() => {
    if (matriculaRef.current) {
      matriculaRef.current.focus(); // Establecer el foco después de cambiar las sugerencias
    }
  }, [matriculaSugerencias]);
  
  useEffect(() => {
    console.log(idtrayecto)
    if (idtrayecto) {
      setSelectedTrayecto(idtrayecto); // Setear el trayecto seleccionado desde el parámetro de la URL
    }
  }, [idtrayecto]);

  // Verifica la validez del formulario
  const isFormValidNow = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleSugerenciaClick = (vehiculo) => {
    console.log(vehiculo)
    // Al hacer clic en una sugerencia, completar los campos con los valores correspondientes
    form.setFieldsValue({
      matriculaVehiculo: vehiculo.matricula,  // Rellenamos matrícula
      tipoVehiculo: vehiculo.id_tipo_vehiculo,   // Rellenamos tipo de vehículo
      cedula: vehiculo.cedula,                 // Rellenamos cédula
      nombreConductor: vehiculo.nombre,        // Rellenamos nombre del conductor
      telefonoConductor: vehiculo.telefono || "",  // Asegúrate de que el teléfono no sea undefined
    });
  setTipoVehiculo(vehiculo.id_tipo_vehiculo);
  setMatriculaVehiculo(vehiculo.matricula);
    // Limpiar las sugerencias
    setMatriculaSugerencias([]); // Limpiamos las sugerencias de matrícula
  };
  
  
  useEffect(() => {
    buscarMatricula(matriculaVehiculo);
    form.setFieldsValue({
      matriculaVehiculo: matriculaVehiculo,  // Rellenamos matrícula
    });
  }, [matriculaVehiculo]);
  // Verifica si el formulario es válido para habilitar/deshabilitar el botón
  useEffect(() => {
    const formValid = isFormValidNow();
    // const isSubmitButtonDisabled = !(formValid && photoTaken);
    setIsFormValid(formValid);
  }, [ tipoVehiculo, matriculaVehiculo]);

  const isSubmitButtonDisabled = !(isFormValid);

  
  return (
    <div className="container" style={{ padding: '30px' }}>
      <Title level={2}>Generar Factura</Title>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
            initialValues={{
              tipoVehiculo,
              matriculaVehiculo,
              matriculaRemolque,
              cedula,
              nombreConductor,
              telefonoConductor,
              selectedTrayecto
            }}
>
  <Form.Item>
    <h4>{selectedTrayecto === '1' ? 'Recorrido A Magdalena - Atlantico' : 'Recorrido B Atlantico Magdalena'}</h4>
  </Form.Item>

  {/* Vehículo */}
  <Form.Item
  label="Matrícula del Vehículo"
  name="matriculaVehiculo"
  rules={[{ required: true, message: 'Por favor ingrese la matrícula del vehículo' }]}
>
  <Input
    ref={matriculaRef}
    value={matriculaVehiculo}
    maxLength={7}
    onChange={(e) => {
      const value = e.target.value;
      setMatriculaVehiculo(value);
      if (value.trim() === "") {
        setMatriculaSugerencias([]);
      } else {
        buscarMatricula(value); 
      }
    }}
    placeholder="Ingrese matrícula del vehículo"
  />

  {/* Solo mostrar las sugerencias si hay resultados */}
  {filteredSugerencias.length > 0 && matriculaVehiculo.trim() !== "" && (
    <div style={{ marginTop: '10px', border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}>
      {filteredSugerencias.map((vehiculo) => (
        <div
          key={vehiculo.id_vehiculo}
          onClick={() => handleSugerenciaClick(vehiculo)}
          style={{ cursor: 'pointer', padding: '5px 0' }}
        >
          {vehiculo.matricula} - {vehiculo.tipo_vehiculo}
        </div>
      ))}
    </div>
  )}
</Form.Item>











  <Form.Item
    label="Tipo de Vehículo"
    name="tipoVehiculo"
    rules={[{ required: true, message: 'Por favor seleccione el tipo de vehículo' }]}
  >
    <Select
      value={tipoVehiculo}
      onChange={setTipoVehiculo}
      placeholder="Seleccione el tipo de vehículo"
    >
      {tiposVehiculos.map((vehiculo) => (
        <Option key={vehiculo.id_tipo_vehiculo} value={vehiculo.id_tipo_vehiculo}>
          {vehiculo.tipo_vehiculo}
        </Option>
      ))}
    </Select>
  </Form.Item>

 
  <Form.Item
    label="Matrícula del Remolque (Opcional)"
    name="matriculaRemolque"
  >
    <Input
      value={matriculaRemolque}
      maxLength={7}
      onChange={(e) => setMatriculaRemolque(e.target.value)}
      placeholder="Ingrese matrícula del remolque (opcional)"
    />
  </Form.Item>

  {/* Información del Conductor */}
  <Title level={4}>Información del Conductor</Title>
  
  <Form.Item
    label="Cédula"
    name="cedula"

  >
    <Input
      value={cedula}
      maxLength={10}  // Limitar a 10 caracteres
      type="number"
      onChange={(e) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
          setCedula(value);
        }
      }}
      onKeyPress={(e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      }}
      placeholder="Ingrese cédula del conductor"
    />
  </Form.Item>

  <Form.Item
    label="Nombre"
    name="nombreConductor"
    rules={[{ message: 'Por favor ingrese el nombre del conductor' }]}
  >
    <Input
      value={nombreConductor}
      onChange={(e) => setNombreConductor(e.target.value)}
      placeholder="Ingrese nombre del conductor"
    />
  </Form.Item>

  <Form.Item
    label="Celular"
    name="telefonoConductor"
    rules={[
      { pattern: /^[0-9]{10}$/, message: 'El Celular debe ser un número de 10 dígitos' },
    ]}
  >
    <Input
      value={telefonoConductor}
      maxLength={10}  // Limitar a 10 caracteres
      type="number"
      onChange={(e) => {
        const value = e.target.value;
        if (/^\d{0,10}$/.test(value)) {
          setTelefonoConductor(value);
        }
      }}
      onKeyPress={(e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      }}
      placeholder="Ingrese teléfono del conductor"
    />
  </Form.Item>

  {/* Botón para tomar la foto */}
  <Button
    type="default"
    onClick={requestCameraPermission}
    icon={<CameraOutlined />}
    style={{ width: '100%', marginBottom: '20px' }}
  >
    Tomar Foto
  </Button>

  {isCameraOpen && (
    <div style={{ marginBottom: '20px' }}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width="100%"
        videoConstraints={{
          facingMode: "environment"
        }}
      />
      <Button onClick={capturePhoto} type="primary" block>
        Capturar Foto
      </Button>
    </div>
  )}

  {image && <img src={image} alt="captura" style={{ width: '100%', marginTop: '10px' }} />}

  {/* Botón para enviar el formulario */}
  <Button
    type="primary"
    htmlType="submit"
    disabled={isSubmitButtonDisabled}
    loading={false}
    style={{ width: '100%' }}
  >
    Generar Factura
  </Button>
</Form>

    </div>
  );
}

export default InvoiceForm;
