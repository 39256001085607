import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Divider, Row, Col, DatePicker, message, Modal, Table, Tag } from 'antd';
import { NumericFormat } from 'react-number-format';  // Importación correcta
import axios from 'axios'; // Necesitarás instalar axios
import API_BASE_URL from '../components/apiService';
import { SearchOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf'; // Para generar el PDF
import 'jspdf-autotable'; // Para las tablas en PDF
import moment from 'moment';  // Para manejar fechas

const { Title } = Typography;

function GastosOperacion() {
  // Estados para los valores del formulario
  const [totalAmount, setTotalAmount] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
    const [totalingresos, setTotalIngresos] = useState(0);
    const [totalegresos, setTotalEgresos] = useState(0);
    const [balanceTotal, setBalanceTotal] = useState(0);
    
  const [fechaIni, setFechaIni] = useState(null);  // Estado para la fecha inicio

  // Estado para el modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Estados para los datos de la tabla
  const [operationalCosts, setOperationalCosts] = useState([]);
  const [total, setTotal] = useState(0); // Total de registros
  const [page, setPage] = useState(1);   // Página actual
  const [loading, setLoading] = useState(false); // Estado de carga

  // Función para obtener los gastos desde la API
  const fetchGastos = async (page, fechaIni = null) => {
    setLoading(true);
    const token = localStorage.getItem('token'); // Obtener el token desde localStorage

    try {
      const params = {
        page,
        limit: 1000, // Límite de resultados por página
        ...(fechaIni && { fecha_ini: fechaIni }) // Añadir fecha inicio si está definida
      };

      const response = await axios.get(
        `${API_BASE_URL}/admin/gastos/all`,
        {
          params,
          headers: {
            Authorization: `${token}` // Pasamos el token en los encabezados
          }
        }
      );

      if (response.data.success) {
        setOperationalCosts(response.data.data.gastos); // Datos de los gastos
        setTotal(response.data.data.count);  // Total de registros

        const { total_ingresos, total_egresos, balance } = response.data.data.gastos[0]; 
        console.log(response.data.data.gastos[0],"***********")
        setTotalIngresos(total_ingresos); // Este es el total de ingresos
        setTotalEgresos(total_egresos); // Este es el total de egresos
        setBalanceTotal(balance); // Este es el total de egresos
      } else {
        message.error('Error al obtener los datos');
        setBalanceTotal(0);
        setTotalIngresos(0); // Este es el total de ingresos
        setTotalEgresos(0); // Este es el total de egresos
      }
    } catch (error) {
      console.error('Error al hacer la solicitud', error);
      message.error('Hubo un error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  // Llamar a la API al cambiar de página o filtrar por fecha
// Llamar a la API cuando la fecha cambie
useEffect(() => {
  if (fechaIni) {
    fetchGastos(page, fechaIni.format('YYYY-MM-DD'));
  }
}, [fechaIni, page]);  // Reemplazamos `page` y `fechaIni` en el arreglo de dependencias

  

  // Función para manejar el cambio de página en la tabla
  const handlePageChange = (page) => {
    setPage(page);  // Actualizamos la página actual
  };

  // Función para filtrar las columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = operationalCosts.filter((record) =>
      record[dataIndex].toString().toLowerCase().includes(selectedKeys[0].toLowerCase())
    );
    setOperationalCosts(filteredData);
  };
  
  // Filtro de búsqueda general en columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  // Definir las columnas de la tabla
  const columns = [
    {
      title: 'Descripcion',
      dataIndex: 'trayecto',
      key: 'trayecto',
      ...getColumnSearchProps('trayecto')
    },
    {
      title: 'Vehículos',
      dataIndex: 'vehiculos',
      key: 'vehiculos',
      ...getColumnSearchProps('vehiculos')
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      render: (amount) => (
        <NumericFormat 
          value={amount} 
          displayType="text"
          thousandSeparator={true} 
          prefix="$" 
          decimalScale={2}
          fixedDecimalScale 
        />
      ),
    },
    {
      title: 'Movimiento',
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion',
      render: (_, record) => (
        <Tag color={record.tipo_operacion === 'INGRESO' ? 'green' : 'red'}>
          {record.tipo_operacion === 'INGRESO' ? 'INGRESO' : 'EGRESO'}
        </Tag>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      ...getColumnSearchProps('fecha_creacion')
    }
  ];

  // Configuración de la paginación
  // const paginationConfig = {
  //   current: page,
  //   total: total,
  //   pageSize: 1000,
  //   onChange: handlePageChange, // Cambiar de página
  // };

  // Función para obtener el monto total
  const handleGetTotalAmount = async () => {
    if (fechaIni) {
      const fechaIniFormatted = fechaIni.format('YYYY-MM-DD');
      
      const token = localStorage.getItem('token'); // Obtener el token desde el localStorage

      try {
        const response = await axios.get(
          `${API_BASE_URL}/admin/ingresos/all?fecha_ini=${fechaIniFormatted}`,
          {
            headers: {
              Authorization: `${token}` // Pasamos el token en los encabezados
            }
          }
        );
        if (response.data.success) {
          const totalPrecio = response.data.data.jornadas[0].total;
          setTotalAmount(totalPrecio); // Actualizamos el monto total con la respuesta
          message.success('Monto total obtenido exitosamente');
        } else {
          message.error('Error al obtener el monto total');
        }
      } catch (error) {
        console.error('Error al obtener el monto total', error);
        message.error('Error al obtener el monto total');
      }
    } else {
      message.error('Por favor, seleccione una fecha de inicio.');
    }
  };

  // Función para enviar el gasto operacional al API
  const handleSubmit = async () => {
    const token = localStorage.getItem('token'); // Obtener el token desde el localStorage
  
    const payload = {
      monto_total_periodo: totalAmount,  // El monto total obtenido
      fecha_inicial: fechaIni.format('YYYY-MM-DD'),  // Formatear fecha
      descripcion_gasto_operacional: description,  // Descripción del gasto
      total_gasto_operacional: amount,  // Sumar el total de los gastos operacionales
    };
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/admin/gastooperacional`, 
        payload,
        {
          headers: {
            Authorization: `${token}`,  // Pasamos el token en los encabezados
          }
        }
      );
      
      if (response.data.success) {
        message.success('Gasto operacional creado con éxito');
        
        // Limpiar el formulario
        setTotalAmount('');
        setDescription('');
        setAmount('');
        setFechaIni(null);
  
        // Recargar la tabla
        if (fechaIni) {
          fetchGastos(page, fechaIni.format('YYYY-MM-DD'));
        }  // Vuelve a llamar al servicio para actualizar los datos
  
        // Opcional: Cerrar el modal después de enviar
        setIsModalVisible(false);
      } else {
        message.error('Hubo un error al crear el gasto operacional');
      }
    } catch (error) {
      console.error('Error al enviar los datos al API', error);
      message.error('Hubo un error al enviar los datos');
    }
  };
  

  // Función para manejar la apertura del modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Función para manejar el cierre del modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };
// Función para formatear el valor como moneda
const formatCurrency = (value) => {
  // Asegurarse de que el valor sea un número
  const numberValue = parseFloat(value);

  // Verificar si el valor es un número válido
  if (isNaN(numberValue)) {
    return '$0.00'; // Si no es un número válido, devolver '$0.00'
  }

  // Formatear el valor como moneda con separación de miles
  return `$${numberValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

  // Función para exportar la tabla a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
  
    // Definir el título del PDF
    doc.setFontSize(16);
    doc.text('Ingresos Diarios vs Gastos', 14, 20);
  
    // Crear la tabla con los datos de los gastos
    doc.autoTable({
      head: [
        ['Descripcion del movimiento', 'Tipo de movimiento', 'Monto', 'Cantidad de vehículos', 'Fecha']
      ],
      body: operationalCosts.map(record => [
        record.trayecto,
        record.tipo_operacion,
        formatCurrency(record.monto),
        record.vehiculos,
        record.fecha_creacion,
      ]),
      startY: 30, // Ubicación de inicio de la tabla
      theme: 'striped',
    });
  
    // Añadir totales de ingresos, egresos y balance
    // const totalIngresos = calculateTotalIngresos();
    // const totalEgresos = calculateTotalEgresos();
    // const balanceTotal = totalIngresos + totalEgresos;
  
    // Definir la posición para los totales
    const startY = doc.lastAutoTable.finalY + 10;  // Después de la tabla, con un margen de 10
  
    // Mostrar los totales en el PDF
    doc.setFontSize(12);
    doc.text(`Total Ingresos: ${formatCurrency(totalingresos)}`, 14, startY);
    doc.text(`Total Egresos: ${formatCurrency(totalegresos)}`, 14, startY + 10);
    doc.text(`Balance Total: ${formatCurrency(balanceTotal)}`, 14, startY + 20);
  
    // Guardar el PDF
    doc.save('Balance Diario.pdf');
  };
  
// Función para calcular el total de ingresos
// const calculateTotalIngresos = () => {
//   const totalIngresos = operationalCosts
//     .filter((record) => record.tipo_operacion === 'INGRESO')  // Filtramos solo los ingresos
//     .reduce((acc, record) => acc + parseFloat(record.monto), 0);  // Sumamos los montos de los ingresos
//   return totalIngresos;
// };
// const calculateTotalEgresos = () => {
//   const totalIngresos = operationalCosts
//     .filter((record) => record.tipo_operacion === 'EGRESO')  // Filtramos solo los ingresos
//     .reduce((acc, record) => acc + parseFloat(record.monto), 0);  // Sumamos los montos de los ingresos
//   return totalIngresos;
// };

  return (
    <div style={{ padding: '10px' }}>
      <Title level={5}>Ingresos y egresos generales</Title>

      {/* Botón para abrir el modal con el formulario */}
      <Button type="primary" onClick={showModal} style={{ marginBottom: 20 }} block>
        Agregar Gasto Operacional
      </Button>

      {/* Filtro de fecha de inicio */}
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={12} md={8}>
          <DatePicker
            style={{ width: '100%' }}
            value={fechaIni}
            onChange={(date) => setFechaIni(date)}
            format="YYYY-MM-DD"
            placeholder="Fecha de inicio"
          />
        </Col>

      </Row>

      {/* Tabla de gastos operacionales */}
      <Table
        columns={columns}
        dataSource={operationalCosts}
        rowKey="idgasto_operacional"
        pagination={false} // Paginación
        loading={loading} // Mostrar carga mientras se obtienen los datos
        scroll={{ x: 'max-content' }}  // Hacer la tabla desplazable horizontalmente
        responsive={true} 
      />
      <Divider />
      <Table
  columns={[
    {
      title: 'Ingresos',
      dataIndex: 'totalIngresos',
      key: 'totalIngresos',
      style:{
        with:'5%'
      },
      render: () => formatCurrency(totalingresos),  // Formatear como moneda
    },
    {
      title: 'Egresos',
      dataIndex: 'totalEgresos',
      key: 'totalEgresos',
      style:{
        with:'5%'
      },
      render: () => formatCurrency(totalegresos),  // Formatear como moneda
    },
    {
      title: 'Total',
      dataIndex: 'balanceTotal',
      key: 'balanceTotal',
      style:{
        with:'5%'
      },
      render: () => formatCurrency(balanceTotal),  // Formatear como moneda
    },
  ]}
  dataSource={operationalCosts.slice(0, 1)}
  responsive={true} 
  scroll={{ x: 'max-content' }}
  pagination={false}  // Sin paginación ya que solo hay una fila
  showHeader={true}  // Muestra los encabezados
  footer={null}  // No necesitas footer en esta tabla
  style={{ marginTop: 20 }}  // Estilo opcional para separar las tablas

/>
      <Divider />

      {/* Botón para exportar a PDF */}
      <Button type="default" onClick={exportToPDF} style={{ marginBottom: 20 }} block>
        Exportar a PDF
      </Button>

      {/* Modal para el formulario de gastos operacionales */}
      <Modal
        title="Agregar Gasto Operacional"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="90%"  // Hace que el modal sea más pequeño en dispositivos móviles
      >
        <Form layout="vertical">
          {/* Campo para seleccionar el periodo */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Fecha del egreso">
                <DatePicker
                  style={{ width: '100%' }}
                  value={fechaIni}
                  onChange={(date) => setFechaIni(date)}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Campos para el gasto operacional */}
          <Form.Item label="Descripción del Gasto Operacional">
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Ejemplo: Pago de nómina"
            />
          </Form.Item>

          <Form.Item label="Monto del Gasto Operacional">
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              customInput={Input}  // Usamos Input de Ant Design
              placeholder="Monto del gasto operacional"
            />
          </Form.Item>

          {/* Botón para enviar el gasto operacional */}
          <Form.Item>
            <Button type="primary" onClick={handleSubmit} block>
              Enviar Gasto Operacional
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default GastosOperacion;
